import React from "react";
import { useModal } from '../../contexts/modal'

const VerifyMoveModal = ({ updateJobStatus, destination, jobId, title, message, subMessage, oneButton, cb }) => {
  const { modalMethods: { close }} = useModal()

  const handleClose = () => {
    close()
  }

  return (
    <div className="flex flex-col items-start w-fit lg:w-[460px] h-fit p-6 bg-white rounded-[8px]">
      <div className='pb-6'>

        <div className='flex flex-row items-center'>
          <p className='text-[29px] font-bold text-secondary-blue'>
            {title}
          </p>
        </div>
        <p className='text-[16px] text-light-blue'>
          {message}
        </p>
        <p className={`text-[16px] text-light-blue ${subMessage ? '' : 'hidden'}`}>
          {subMessage}
        </p>

      </div>
      <div className='flex w-full items-center justify-around'>
        {oneButton ?
          (
            <>
              <button
                className='rounded px-4 py-1 text-secondary-blue text-lg bg-white border-2 border-light-blue font-semibold'
                onClick={() => {
                  handleClose()
                }}
              >
                Close
              </button>
            </>
          ) : (
            <>
              <button
                className='rounded px-9 py-3 text-secondary-blue bg-white border-2 border-light-blue font-bold'
                onClick={() => {
                  handleClose()
                }}
              >
                Cancel
              </button>

              <button
                className='rounded px-9 py-3 font-bold bg-gunite-red text-white'
                onClick={() => {
                  if (cb) {
                    cb()
                  }
                  updateJobStatus(destination, jobId)
                  handleClose()
                }}
              >
                Yes
              </button>
            </>
          )
        }
      </div>
    </div>
  )
}

export default VerifyMoveModal;
