import React from 'react'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'
import CalendarJobCard from '../CalendarJobCard'

const Timeslot = ({ scheduledDate, crewId, timeslotJob, shoot, color }) => {
  const { interval, displayMode } = useSchedulerCalendar()

  const cardData = {
    scheduledDate: scheduledDate,
    crewId: crewId,
    shift: shoot
  }

  const timeslotDisplay = () => {
    if (interval === 'week' && displayMode === 'vertical') {
      return (
        <>
          {timeslotJob
            ?
            <CalendarJobCard job={timeslotJob} color={color} />
            :
            <div
              className={`droppable bg-opacity-30 flex justify-center items-center ${color} 
              basis-1/3 border-dashed`}
              data-timeslotdata={JSON.stringify(cardData)}
            />
          }
        </>
      )
    } else if (interval === 'week' && displayMode === 'horizontal') {
      return (
        <>
          {timeslotJob
            ?
            <CalendarJobCard job={timeslotJob} color={color} />
            :
            <div
              className={`droppable h-[44px] flex flex-1 justify-center items-center ${color} bg-opacity-30 border-dashed`}
              data-timeslotdata={JSON.stringify(cardData)}
            />
          }
        </>
      )
    } else if (interval === 'month' && displayMode === 'vertical') {
      return (
        <>
          {timeslotJob
            ?
            <CalendarJobCard job={timeslotJob} color={color} />
            :
            <div
              className={`droppable flex flex-1 justify-center items-center ${color} bg-opacity-30 h-1/4 border-dashed`}
              data-timeslotdata={JSON.stringify(cardData)}
            />
          }
        </>
      )
    } else if (interval === 'month' && displayMode === 'horizontal') {
      return (
        <>
          {timeslotJob
            ?
            <CalendarJobCard job={timeslotJob} color={color} />
            :
            <div
              className={`droppable flex h-[20px] justify-center items-center ${color} bg-opacity-30 border-dashed`}
              data-timeslotdata={JSON.stringify(cardData)}
            />
          }
        </>
      )
    }
  }


  return (
    <>{timeslotDisplay()}</>
  )
}

export default Timeslot