import React, { createContext, useContext, useState, useEffect } from 'react'
import cookies from 'browser-cookies'
import { useAuth } from './auth'
import Service from '../service'
import { useNavigate, useLocation } from 'react-router-dom';

const SchedulerContext = createContext()

const DEFAULT_SELECTED_SCHEDULER = {
  id: null,
  phone: '',
  location: {
    id: null,
    label: '',
    jobs: [],
    crews: [
      {
        id: null,
        name: '',
        crewMembers: []
      }
    ],
    drivers: []
  }
}

const crewColors = [
  'bg-[#f8f5c2] border-[#f8f5c2]',
  'bg-[#b9fbc0] border-[#b9fbc0]',
  'bg-[#9fe7ff] border-[#9fe7ff]',
  'bg-[#fde4cf] border-[#fde4cf]',
  'bg-[#f1c0e8] border-[#f1c0e8]',
  'bg-[#ffcfd2] border-[#ffcfd2]',
  'bg-[#99e4eb] border-[#99e4eb]',
  'bg-[#d1bba7] border-[#d1bba7]',
  'bg-[#cbbaf0] border-[#cbbaf0]',
  'bg-[#8bebbe] border-[#8bebbe]',
  'bg-[#bcd8ff] border-[#bcd8ff]',
  'bg-[#bcc4d1] border-[#bcc4d1]',
]

const SchedulerContextProvider = props => {
  const { me, logout } = useAuth()
  const [selectedScheduler, setSelectedScheduler] = useState(DEFAULT_SELECTED_SCHEDULER)
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null)
  const [locationDropdownOptions, setLocationDropdownOptions] = useState([])
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedJobNotes, setSelectedJobNotes] = useState({})
  const { accessToken } = Service.getAuthTokens()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const getSelectedJobNotes = async (selectedJobId) => {
    const res = await Service.getJobNotes(selectedJobId);
    const data = await res.json();
    setSelectedJobNotes(data);
  }

  useEffect(()=>{
    if (selectedJobId){
      getSelectedJobNotes(selectedJobId);
    }
  },[selectedJobId])

  useEffect(()=>{
    if (!accessToken && !pathname.includes('onboard')) {
      navigate('/login')
      logout()
    }
  },[accessToken])

  const schedulerIdCookie = me?.role === 'SCHEDULER' ? Number(cookies.get('schedulerId')) : cookies.get('schedulerId')

  const allDrivers = selectedScheduler?.location?.drivers
  const crews = selectedScheduler?.location?.crews
  const undeletedCrews = selectedScheduler?.location?.crews.filter((crew) => crew.deletedAt === null)
  //associate the colors with the crews as a property.
  for (let i = 0; i < undeletedCrews.length; i++) {
    undeletedCrews[i].color = crewColors[i] 
  }
  const calVisibleCrews = undeletedCrews.filter((crew) => crew.isCalendarActive === true)

  useEffect(() => {
    if (me?.scheduler?.length > 0) {
      if (!schedulerIdCookie) {
        logout()
        return
      }

      const schedulerObj = me.scheduler.find((item) => (
        item.id.toString() === String(schedulerIdCookie) && item.location.isPortalActive
      )) || me.scheduler.find((item) => item.location.isPortalActive);
      setSelectedScheduler(schedulerObj);
    }
    if (me?.scheduler){
      let dropdownOptions = []
      if (me?.role === 'BRANCHADMIN' || me?.role === 'SCHEDULER'){
        dropdownOptions = me.scheduler
          .filter(item => item.location.isPortalActive)
          .map(item => ({
            label: item.location.label,
            value: item.location.id
          }));

          dropdownOptions.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          });
        } else {
          // ADMIN
          dropdownOptions = me.scheduler
          .map(item => ({
            label: item.location.label,
            value: item.location.id
          }));
          
          dropdownOptions.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          });
        }
        setLocationDropdownOptions(dropdownOptions);
    }
  }, [me?.scheduler, selectedScheduler?.id, schedulerIdCookie])

  return (
    <SchedulerContext.Provider value={{
      showMobileNav,
      setShowMobileNav, selectedScheduler,
      setSelectedScheduler, selectedJobId,
      setSelectedJobId, locationDropdownOptions,
      setLocationDropdownOptions,
      isAnimatingOut, setIsAnimatingOut,
      selectedStatuses, setSelectedStatuses,
      selectedJobNotes,
      setSelectedJobNotes, getSelectedJobNotes,
      crews, allDrivers,
      undeletedCrews, calVisibleCrews
    }}>
      {props.children}
    </SchedulerContext.Provider>
  )
}

const useScheduler = () => useContext(SchedulerContext)

export { useScheduler }
export default SchedulerContextProvider
