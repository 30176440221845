import React from 'react'
import Input from '../Input'
import Selector from '../Selector';
import { useResponsive } from '../../contexts/responsive';
import _ from 'underscore';
import { FIFTY_STATES } from '../../constants'
import { asrBranches } from '../../utils';

const JobDetailsForm3a = ({ job, i, jobDetails, setJobDetails, skipASRChoice }) => {
  const { isMobileView } = useResponsive();

  const uniqueId = `input-${_.uniqueId()}`

  const ZIP_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;

  const jobNameError = !job.jobName
  const addressError = !job.address
  const cityError = !job.city
  const zipCodeError = !ZIP_REGEX.test(jobDetails[i].zipCode)
  const gateCodeError = !job.gateCode
  const residentialOrCommercialError = !jobDetails[i].residentialOrCommercial

  return (
    <form key={`job${i}`} className="flex flex-col gap-6">
      <p className="text-secondary-blue/60 text-base font-semibold">Address
        {i > 0 &&
          ` for Job #${i + 1}`
        }</p>
      {/*Job name field*/}
      <Input
        value={jobDetails[i].jobName || ''}
        placeholder={'Job Name *'}
        onChange={(e) => {
          const newJob = {
            ...jobDetails[i],
            jobName: e.target.value
          }
          const newJobDetails = [
            ...jobDetails.slice(0, i),
            newJob,
            ...jobDetails.slice(i + 1),
          ]
          setJobDetails(newJobDetails)
        }}
        isError={jobNameError}
      />
      {/* address field */}
      <div className="lg:w-full">
        <Input
          value={jobDetails[i].address || ''}
          placeholder={'Job Site Address *'}
          onChange={(e) => {
            const newJob = {
              ...jobDetails[i],
              address: e.target.value
            }
            const newJobDetails = [
              ...jobDetails.slice(0, i),
              newJob,
              ...jobDetails.slice(i + 1),
            ]
            setJobDetails(newJobDetails)
          }}
          isError={addressError}
        />
      </div>
      <div className="flex flex-col lg:flex-row gap-6">
        {/* City field */}
        <div className="relative lg:w-1/3 group">
          <Input
            value={jobDetails[i].city || ''}
            placeholder={'City *'}
            onChange={(e) => {
              const newJob = {
                ...jobDetails[i],
                city: e.target.value
              }
              const newJobDetails = [
                ...jobDetails.slice(0, i),
                newJob,
                ...jobDetails.slice(i + 1),
              ]
              setJobDetails(newJobDetails)
            }}
            isError={cityError}
          />
        </div>

        {/* State field (selector) */}
        <div className="relative lg:w-1/3">
          <Selector
            value={jobDetails[i].state || ''}
            placeholder={'State *'}
            options={FIFTY_STATES}
            onChange={(e) => {
              const newJob = {
                ...jobDetails[i],
                state: e.target.value
              }
              const newJobDetails = [
                ...jobDetails.slice(0, i),
                newJob,
                ...jobDetails.slice(i + 1),
              ]
              setJobDetails(newJobDetails)
            }}
          />
        </div>

        {/* Zip code field */}
        <div className="relative lg:w-1/3 group">
          <Input
            value={jobDetails[i].zipCode || ''}
            placeholder={'Zip code *'}
            onChange={(e) => {
              const newJob = {
                ...jobDetails[i],
                zipCode: e.target.value
              }
              const newJobDetails = [
                ...jobDetails.slice(0, i),
                newJob,
                ...jobDetails.slice(i + 1),
              ]
              setJobDetails(newJobDetails)
            }}
            isError={zipCodeError}
            numbersOnly={true}
          />
        </div>
      </div>
      {/* gate code field */}
      <div className="lg:w-full">
        <Input
          value={jobDetails[i].gateCode || ''}
          placeholder={'Gate Code *'}
          onChange={(e) => {
            const newJob = {
              ...jobDetails[i],
              gateCode: e.target.value
            }
            const newJobDetails = [
              ...jobDetails.slice(0, i),
              newJob,
              ...jobDetails.slice(i + 1),
            ]
            setJobDetails(newJobDetails)
          }}
          isError={gateCodeError}
        />
      </div>
      {/* notes field */}
      <div className="relative group">
        <textarea
          name="notes"
          id={`${uniqueId}-notes`}
          className="peer mt-0 block w-full h-14 lg:h-28 px-3 pb-2 pt-6
            border-0 border-b border-black/42 focus:ring-0 rounded-t
            focus:border-black bg-tertiary-blue/5 placeholder-transparent
            font-semibold text-base"
          placeholder="Job Site Notes"
          value={jobDetails[i].notes || ''}
          onChange={(e) => {
            const newJob = {
              ...jobDetails[i],
              notes: e.target.value
            }
            const newJobDetails = [
              ...jobDetails.slice(0, i),
              newJob,
              ...jobDetails.slice(i + 1),
            ]
            setJobDetails(newJobDetails)
          }}
        />
        <label
          className="absolute left-3 top-2 text-xs font-normal text-black/60 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-4 peer-focus:text-xs peer-focus:top-2"
          htmlFor={`${uniqueId}-notes`}>
          Job Site Notes
        </label>
      </div>
      {/*Residential / Commerical selector*/}
      <div className={`${!isMobileView && 'flex'}`}>
        <div className={`relative lg:flex lg:gap-6 ${!isMobileView ? 'w-1/2' : 'w-full'}`}>
          <Selector
            value={jobDetails[i].residentialOrCommercial || ''}
            placeholder={'Residential or Commercial? *'}
            options={['Residential', 'Commercial']}
            onChange={(e) => {
              const newJob = {
                ...jobDetails[i],
                residentialOrCommercial: e.target.value
              }
              const newJobDetails = [
                ...jobDetails.slice(0, i),
                newJob,
                ...jobDetails.slice(i + 1),
              ]
              setJobDetails(newJobDetails)
            }}
            isError={residentialOrCommercialError}
          />
        </div>
        {/*HUD CHECKBOX*/}
        <div className={`flex w-1/2 ${!isMobileView ? 'pl-[15px] mt-[15px]' : 'mt-[25px]'}`}>
          <input
            id={`${uniqueId}-hud`}
            type='checkbox'
            value={jobDetails[i].isHudJob || undefined}
            className='mt-[4px] text-tertiary-blue'
            onChange={(e) => {
              const newJob = {
                ...jobDetails[i],
                isHudJob: e.target.checked
              }
              const newJobDetails = [
                ...jobDetails.slice(0, i),
                newJob,
                ...jobDetails.slice(i + 1),
              ]
              setJobDetails(newJobDetails)
            }}
            checked={jobDetails[i].isHudJob}>
          </input>
          <label
            className='text-secondary-blue text-opacity-60 font-semibold ml-[5px]'
            htmlFor={`${uniqueId}-hud`}
          >
            HUD Job
          </label>
        </div>
      </div>

      <div className={`${isMobileView ? 'flex flex-col gap-5' : 'flex justify-between'}`}>
        {/*ELECTRICITY CHECKBOX*/}
        <div className={`flex ${!isMobileView ? '' : 'w-full'}`}>
          <input
            id={`${uniqueId}-electricity`}
            type='checkbox'
            value={jobDetails[i].electricityOnSite || undefined}
            className='mt-[4px] text-tertiary-blue'
            onChange={(e) => {
              const newJob = {
                ...jobDetails[i],
                electricityOnSite: e.target.checked
              }
              const newJobDetails = [
                ...jobDetails.slice(0, i),
                newJob,
                ...jobDetails.slice(i + 1)
              ]
              setJobDetails(newJobDetails)
            }}
            checked={jobDetails[i].electricityOnSite}
          />
          <label
            className='text-secondary-blue text-opacity-60 font-semibold ml-[5px]'
            htmlFor={`${uniqueId}-electricity`}
          >
            Electricity On Site?
          </label>
        </div>
        {/*WATER CHECKBOX*/}
        <div className={`flex ${!isMobileView ? '' : 'w-full'}`}>
          <input
            id={`${uniqueId}-water`}
            type='checkbox'
            value={jobDetails[i].waterOnSite || undefined}
            className='mt-[4px] text-tertiary-blue'
            onChange={(e) => {
              const newJob = {
                ...jobDetails[i],
                waterOnSite: e.target.checked
              }
              const newJobDetails = [
                ...jobDetails.slice(0, i),
                newJob,
                ...jobDetails.slice(i + 1)
              ]
              setJobDetails(newJobDetails)
            }}
            checked={jobDetails[i].waterOnSite}
          />
          <label
            className='text-secondary-blue text-opacity-60 font-semibold ml-[5px]'
            htmlFor={`${uniqueId}-water`}
          >
            Water On Site?
          </label>
        </div>
        {(asrBranches.includes(jobDetails[0].locationId) || !skipASRChoice) && //display if job is at a location with asr/waterproofing.  use the first element of the jobDetails array to determine this
          <div className={`flex gap-2 items-center ${!isMobileView ? '' : 'w-full'}`}>
            <input
              type='checkbox'
              id={`waterproofing${i}`}
              value={jobDetails[i].waterproofing || ''}
              className='text-secondary-blue'
              onChange={(e) => {
                const newJob = {
                  ...jobDetails[i],
                  waterproofing: e.target.checked
                }
                const newJobDetails = [
                  ...jobDetails.slice(0, i),
                  newJob,
                  ...jobDetails.slice(i + 1)
                ]
                setJobDetails(newJobDetails)
              }}
              checked={jobDetails[i].waterproofing}
            />
            <label className='text-tertiary-blue/60 font-semibold' htmlFor={`waterproofing${i}`}>
              Waterproofing
            </label>
          </div>
        }
      </div>
    </form>
  )
}


export default JobDetailsForm3a;
