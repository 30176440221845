import React, { useContext } from 'react';
import { ResponsiveContext } from '../../contexts/responsive'
import JobStatusIcon from '../JobStatusIcon';
import { formatDate } from '../../utils'

const ListItem = ({ job }) => {
  const { requestedDate, submittedAt, address, owner } = job;
  const bizName = owner.customer.bizName;
  const { responsive, BREAKPOINTS } = useContext(ResponsiveContext);
  const { width } = responsive;
    
  
    
    return (
        <div className="grid grid-cols-[1fr_1fr] md:grid-cols-[1.5fr_0.75fr_0.75fr_1.5fr_1fr_1.5fr] gap-4 py-2 px-6 md:px-3 border-b border-navy-light">
              {job.displayName}
            {(width > BREAKPOINTS.md) && (
            <>
                <div className='text-gray-blue'>S {formatDate(submittedAt)}</div>
                <div className='text-gray-blue'>R {formatDate(requestedDate)}</div>
                <div className='text-gray-blue'>{address}</div>
                <div className='text-gray-blue'>{bizName}</div>
            </>
          )}
            <div className='text-gray-blue flex justify-end items-center'>
              <JobStatusIcon job={job} />
            </div>
        </div>
        );      
    }

export default ListItem;
