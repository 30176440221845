import React, { useState, useEffect, useRef } from 'react'
import service from "../../service"
import PopupSearchBox from '../PopupSearchBox'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useModal } from '../../contexts/modal';
import CustomersNewJobModal from '../CustomersNewJobModal';
import { useScheduler } from '../../contexts/scheduler';
import { useJobDetailsModal } from '../../contexts/jobDetailsModal';
import { useAuth } from '../../contexts/auth';
import NewCustomerModal from '../NewCustomerModal';


const CustomersList = () => {
  const [allCustomers, setAllCustomers] = useState([])
  const [sortedCustomers, setSortedCustomers] = useState([])
  const [customerList, setCustomerList] = useState([])
  const [page, setPage] = useState(1)
  const [isLastPage, setIsLastPage] = useState(false)
  const itemCount = useRef({ start: 1, end: 1 })
  const [searchTerm, setSearchTerm] = useState('')
  const { modalMethods } = useModal()
  const { me } = useAuth();

  const { selectedScheduler, setSelectedJobId } = useScheduler();
  const { setShowJobDetailsModal, setDetailsTabEditing, setSelectedJobDetailsTab, setUpdatedCustomer, setUpdatedJob, setUpdatedPool } = useJobDetailsModal()

  const listenForMe = useRef({ listen: false, id: null })

  const getCustomers = async () => {
    const customerData = await service.getAllCustomers()
    // Sort by bizName
    customerData.sort((a, b) => {
      if (a.bizName.toLowerCase() < b.bizName.toLowerCase()) return -1;
      if (a.bizName.toLowerCase() > b.bizName.toLowerCase()) return 1;
      return 0;
    });
    setAllCustomers(customerData)
    setSortedCustomers(customerData)
  }

  const handleForward = () => {
    // check if we are at end of list
    if (!isLastPage) {
      setPage(page + 1)
    }
  }

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const handleOpenModal = (customer) => {
    modalMethods.setContent(
      <CustomersNewJobModal
        customer={customer}
        selectedScheduler={selectedScheduler}
        listenForMe={listenForMe}
      />
    )
    modalMethods.open()
  }

  const updateCustomerList = () => {
    getCustomers()
  }

  useEffect(() => {
    if (listenForMe.current.listen) {
      const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
      const newJob = me.scheduler[selectedSchedulerIndex].location.jobs.find(job => job.id === listenForMe.current.id)
      setSelectedJobId(listenForMe.current.id)
      setUpdatedJob({
        residentialOrCommercial: newJob.residentialOrCommercial,
        jobName: newJob.jobName,
        address: newJob.address,
        city: newJob.city,
        state: newJob.state,
        zipCode: newJob.zipCode,
        gateCode: newJob.gateCode,
        notes: newJob.notes,
        isHudJob: newJob.isHudJob,
        electricityOnSite: newJob.electricityOnSite,
        waterOnSite: newJob.waterOnSite,
        supervisorFName: newJob.supervisorFName,
        supervisorLName: newJob.supervisorLName,
        supervisorPhone: newJob.supervisorPhone,
        supervisorEmail: newJob.supervisorEmail,
        guniteType: newJob.guniteType,
      });

      const pool = newJob.pools?.[0];

      setUpdatedPool({
        planUri: pool?.planUri,
        guniteWPlaster: pool?.guniteWPlaster,
        perimeterFt: pool?.perimeterFt,
        perimeterInches: pool?.perimeterInches,
        lengthFt: pool?.lengthFt,
        lengthInches: pool?.lengthInches,
        widthFt: pool?.widthFt,
        widthInches: pool?.widthInches,
        deepEndFt: pool?.deepEndFt,
        deepEndInches: pool?.deepEndInches,
        shallowEndFt: pool?.shallowEndFt,
        shallowEndInches: pool?.shallowEndInches,
        squareFt: pool?.squareFt,
        depth: pool?.depth,
        tanningLedgeFt: pool?.tanningLedgeFt,
        tanningLedgeInches: pool?.tanningLedgeInches,
        benchFt: pool?.benchFt,
        benchInches: pool?.benchInches,
        barstools: pool?.barstools,
        drainCovers: pool?.drainCovers,
        fittings: pool?.fittings,
        steps: pool?.steps,
        spa: pool?.spa,
        basin: pool?.basin,
        raisedBeam: pool?.raisedBeam,
        retainingWalls: pool?.retainingWalls,
        notch: pool?.notch,
        negativeEdge: pool?.negativeEdge,
        newOrReplaster: pool?.newOrReplaster,
        material: pool?.material,
        color: pool?.color,
        estimateNeeded: pool?.estimateNeeded,
        materialAddIns: pool?.materialAddIns,
        poolIsDugOut: pool?.poolIsDugOut,
        plumbingIsDone: pool?.plumbingIsDone,
        weWillPrepPool: pool?.weWillPrepPool,
        waterfall: pool?.waterfall
      });

      setUpdatedCustomer({
        id: newJob?.owner?.customer?.id,
        firstName: newJob?.owner?.customer?.firstName,
        lastName: newJob?.owner?.customer?.lastName,
        bizName: newJob?.owner?.customer?.bizName,
        bizAddress: newJob?.owner?.customer?.bizAddress
      })

      setShowJobDetailsModal(true)
      setDetailsTabEditing(true)
      setSelectedJobDetailsTab('Job Details')
      listenForMe.current = { listen: false, id: null }
    }
  }, [me])

  useEffect(() => {
    // sets customerList when the page is changed, searched or sorted
    const start = ((page - 1) * 10)
    const end = start + 10
    const newList = sortedCustomers.slice(start, end)
    setCustomerList(newList)
    setIsLastPage(end >= sortedCustomers.length ? true : false)
    itemCount.current = { start: start + 1, end: end }
  }, [page, sortedCustomers])

  useEffect(() => {
    const newList = []
    allCustomers.forEach((customer, i) => {
      if (
        customer.bizName.toLowerCase().includes(searchTerm.toLowerCase())
        || (`${customer.firstName} ${customer.lastName}`).toLowerCase().includes(searchTerm.toLowerCase())
        || customer.user.email.toLowerCase().includes(searchTerm.toLowerCase())
        || customer.phone.includes(searchTerm)
      ) {
        newList.push(customer)
      }
    })
    setSortedCustomers(newList)
    setPage(1)
  }, [searchTerm])

  useEffect(() => {
    getCustomers()
  }, [])

  return (
    <div className="flex flex-col items-center justify-between  h-[70%] max-h-[650px] w-full sm:w-[70%] text-dark-blue">
      <div className="flex flex-col w-full h-full items-center rounded-t-2xl border-2 border-b-0 border-navy-light">
        <div className="flex h-16 w-full items-center justify-between px-6 bg-navy-bright bg-opacity-10 rounded-t-2xl border-b-2 border-navy-light">
          <p className="font-semibold text-xl">Customers</p>

          <div className="flex justify-end w-1/2">
            <PopupSearchBox
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </div>
        </div>

        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full">
            <thead>
              <tr className="bg-navy-bright bg-opacity-10 border-b-2 border-navy-light text-gray-blue">
                <th className=" text-left font-normal">
                  <div className="pl-8 inline-size-max">Business</div>
                </th>
                <th className=" text-left font-normal">
                  <div className="pl-8 inline-size-max">Sales Rep</div>
                </th>
                <th className=" text-left font-normal">
                  <div className="pl-8 inline-size-max">email</div>
                </th>
                <th className=" text-left font-normal">
                  <div className="pl-8 inline-size-max">Phone</div>
                </th>
                <th className="font-normal">
                  <div className="px-8 mx-auto inline-size-max">New Job</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {customerList.map((customer, i) => {
                return (
                  <tr key={`customer${i}`} className="border-b-2">
                    <td className=" text-left">
                      <div className="inline-size-max pl-8 py-2">
                        {customer.bizName}
                      </div>
                    </td>
                    <td className=" text-left">
                      <div className="inline-size-max pl-8 py-2">
                        {`${customer.firstName} ${customer.lastName}`}
                      </div>
                    </td>
                    <td className=" text-left">
                      <div className="inline-size-max pl-8 py-2">
                        {customer.user.email}
                      </div>
                    </td>
                    <td className=" text-left">
                      <div className="inline-size-max pl-8 py-2">
                        {customer.phone}
                      </div>
                    </td>
                    <td className=" text-left">
                      <div className="flex items-center justify-center py-2">
                        <button
                          className="border-2 rounded px-2 font-bold"
                          onClick={(e) => {
                            // open modal
                            handleOpenModal(customer);
                          }}
                        >
                          +
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex w-full justify-between h-16 items-center p-3 min-[400px]:px-6 gap-2 bg-navy-bright bg-opacity-10 rounded-b-2xl border-2 border-navy-light">
        <button
          className="flex border-2 rounded px-2 min-[400px]:px-3 py-1 font-bold bg-all-f-white"
          onClick={() => {
            modalMethods.setContent(
              <NewCustomerModal
                selectedScheduler={selectedScheduler}
                updateCustomerList={updateCustomerList}
              />
            );
            modalMethods.open();
          }}
        >
          Add New Customer
        </button>

        <div className="flex items-center justify-end gap-2">
          <p>
            {itemCount.current.start} -{" "}
            {Math.min(itemCount.current.end, sortedCustomers.length)} of{" "}
            {sortedCustomers.length}
          </p>
          <FaChevronLeft
            onClick={handleBack}
            className="cursor-pointer h-4 w-4 inline text-gray-blue"
          />
          <FaChevronRight
            onClick={handleForward}
            className="cursor-pointer h-4 w-4 inline text-gray-blue"
          />
        </div>
      </div>
    </div>
  );
}

export default CustomersList;
