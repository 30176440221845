import React, { useEffect } from "react";
import ProgressPaneMobile from '../../../components/ProgressPaneMobile';
import ProgressPaneDesktop from '../../../components/ProgressPaneDesktop';
import SignUp from "../../../components/SignUp";
import SignIn from '../../../components/SignIn';
import { useResponsive } from '../../../contexts/responsive';
import { useAuth } from "../../../contexts/auth";
import { useProgress } from "../../../contexts/progress";

const GuniteIntake1 = () => {
  const { isMobileView } = useResponsive();
  const { isLoggedIn } = useAuth();
  const { navigateNext, navigatePrevious, pathnameList } = useProgress();

  useEffect(() => {
    if (pathnameList.current[1]) {
      if (isLoggedIn && !pathnameList.current[1]?.includes('intake-1') && !pathnameList.current[1]?.includes('intake-3a')) {
        navigateNext()
      } else if (pathnameList.current[1].includes('intake-3a')) {
        navigatePrevious()
      }
    }
  }, [isLoggedIn])

  return (
    <>
      <div className='flex flex-col lg:flex-row'>
        <div className="lg:w-1/3">
          {isMobileView ?
            <ProgressPaneMobile />
            :
            <ProgressPaneDesktop />
          }
        </div>
        <div className='w-full p-6 lg:p-20'>
          <SignUp />

          <div className="mt-12 flex flex-col gap-6">
            <SignIn />

          </div>
        </div>

      </div>
    </>
  );
}

export default GuniteIntake1
