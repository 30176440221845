import React from "react";
import { useResponsive } from "../../../contexts/responsive";
import { useProgress } from "../../../contexts/progress";
import { useIntakeForm } from "../../../contexts/intakeForm";
import ProgressPaneDesktop from "../../../components/ProgressPaneDesktop";
import ProgressPaneMobile from "../../../components/ProgressPaneMobile";
import WarningMessage from "../../../components/WarningMessage";
import './index.scss';
import Summary from '../../../components/Summary'
import serviceMethods from '../../../service';
import cookies from 'browser-cookies';

const PlasterIntake5 = () => {
  const { jobDetails, destroyJobSpecificCookies, poolDetails } = useIntakeForm()
  const { navigateNext, navigatePrevious } = useProgress();
  const { isMobileView } = useResponsive();
  const menuItems = {
    1: 'Schedule Request',
    2: 'Builder Information',
    3: 'Ready for Work',
    4: 'Address',
    5: 'Pool',
    6: 'Plaster',
    7: 'Supervisor',
  }


  const handleSubmit = async () => {
    const intakeFlowId = Number(cookies.get('intakeFlowId'));
    const res = await serviceMethods.submitJob({intakeFlowId, jobDetails});

    if (res.ok) {
      const { message } = await res.json();
      if (message) {
        destroyJobSpecificCookies();
        navigateNext();
      }
    }
  }

  return (
    <>
      <div className='flex flex-col lg:flex-row'>
        <div className="lg:w-1/3">
          {isMobileView ?
            <ProgressPaneMobile />
            :
            <ProgressPaneDesktop />
          }
        </div>

        <div className={`
          ${isMobileView ?
            'p-[30px] intake_5_outer_container_mobile'

          :
            'p-[80px] w-full intake_5_outer_container_desktop'
          }`}>

          {!isMobileView &&
            <>
              <button
                onClick={()=>{
                  navigatePrevious()
                }}
              >
                <div className="flex gap-2 items-center mb-6">
                  <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
                  <p className="font-semibold text-gunite-red">Back</p>
                </div>
              </button>
              <p className="text-secondary-blue font-bold text-4xl mb-1">Summary</p>
              <div className='mb-6'>
                <WarningMessage />
              </div>
            </>
          }

          {jobDetails.length === 1 ?
            (
              <Summary
                i={0}
                menuItems={menuItems}
                isMobileView={isMobileView}
              />
            ) : (
              poolDetails.map((pool, i) => {
                return (
                  <div
                    className="py-5"
                    key={`summaryWrapper${i}`}
                  >
                    <div className='text-xl font-medium'>
                      {`Job #${i+1}: ${jobDetails[i].jobName}`}
                    </div>
                    <div className="py-5">
                      <Summary
                        i={i}
                        key={`summary${i}`}
                        isGunite={false}
                        menuItems={menuItems}
                        isMobileView={isMobileView}
                      />
                    </div>
                  </div>
                )
              })
            )
          }

          <div className='flex'>
            <button className='mt-[150px] w-full lg:w-auto lg:px-9 lg:py-3
              rounded p-3 text-2xl font-bold bg-gunite-red text-white'
              onClick={()=>{handleSubmit()}}
            >
              Submit Schedule Form
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlasterIntake5;
