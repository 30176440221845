import React from "react";
import { useLongPress } from "@uidotdev/usehooks";
import { useScheduler } from "../../contexts/scheduler";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";
import { useSchedulerCalendarMobile } from "../../contexts/schedulerCalendarMobile";

function JFJobCardMobile({ job, setOpen }) {
  const { setSelectedJobId } = useScheduler()
  const { moveJob, draggedFrom } = useSchedulerCalendarMobile()
  const { setShowJobDetailsModal } = useJobDetailsModal()

  const jobObj = {
    jobId: job.id,
    calendarJobType: 'REGULAR'
  }

  const attrs = useLongPress(
    () => {
      draggedFrom.current = 'jobSidePane'
      moveJob(jobObj)
      setOpen(false)
    },
    {
      threshold: 500,
    }
  );

  const tags = []

  //tags for all kinds of jobs
  if (job?.pools[0]?.newOrReplaster === 'Re-Plaster') {
    tags.push('R')
  }
  if (job?.isHudJob) {
    tags.push('H')
  }
  if (job?.pools[0]?.spa) {
    tags.push('S')
  }
  //tags for plaster jobs
  if (job?.jobType === 'plaster') {
    if (job?.pools[0]?.basin) {
      tags.push('B')
    }
  } else {
    //tags for gunite jobs
    if (job?.guniteType === 'ASR Certification') {
      tags.push('A')
    }
    if (job?.waterproofing === true) {
      tags.push('W')
    }
    if (job?.pools[0]?.negativeEdge) {
      tags.push('NE')
    }
    if (job?.pools[0]?.notch) {
      tags.push('N')
    }
    if (job?.pools[0]?.raisedBeam) {
      tags.push('RB')
    }
    if (job?.pools[0]?.retainingWalls) {
      tags.push('RW')
    }
  }

  const jobDisplayDate = () => {
    if (job.requestedDate) {
      const date = new Date(job.requestedDate);
      return `R ${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`
    }
  }

  const jobSizeDisplay = () => {
    if (job.jobType === 'gunite') return `${job.estimatedYards} yards`
    else return `${job.estimatedIA} IA`
  }

  const stateAbbreviator = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: "IL",
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VI',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY'
  }

  return (
    <div
      className={`flex w-full rounded-[8px] bg-subtle-grey text-dark-blue border shadow-md font-roboto text-[14px]`}
      onClick={async (e) => {
        setSelectedJobId(job.id)
        setShowJobDetailsModal(true)
      }}
      {...attrs}
    >

      <div className='flex flex-col w-[calc(100%-4px)] h-full p-2'>
        <div className='flex justify-between'>
          <p className='font-[800]'>
            {job.jobName?.toUpperCase()}
          </p>
          <p className='text-gray-blue'>
            {jobDisplayDate()}
          </p>
        </div>

        <div className='flex flex-col'>
          <p className='font-[500]'>
            {job.address}, {job.city}, {stateAbbreviator[job.state]}
          </p>
          <p className='font-[500]'>
            {job.owner?.customer?.bizName}
          </p>
          {(job.jobType === 'plaster' && job?.pools[0]?.material)
            &&
            <p className='text-gray-blue leading-none'>
              {job?.pools[0]?.material}
            </p>
          }
        </div>

        <div className='flex justify-between text-[12px] pt-[6px] items-center'>
          <p className='bg-navy-light group-hover:bg-card-accent-hover rounded-md h-fit mt-1 px-[8px] py-[4px] font-[600]'>
            {jobSizeDisplay()}
          </p>
          <div className='tags flex flex-row'>
            {tags.map((tag, i) => (
              <p key={`tag${i}`} className='bg-navy-light group-hover:bg-card-accent-hover rounded-md h-fit p-2 ml-[4px] leading-none font-[600]'>
                {tag}
              </p>
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}

export default JFJobCardMobile;
