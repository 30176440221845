import React, { useState, useEffect } from "react";
import { useIntakeForm } from "../../../contexts/intakeForm";
import ProgressPaneMobile from '../../../components/ProgressPaneMobile';
import ProgressPaneDesktop from '../../../components/ProgressPaneDesktop';
import WarningMessage from "../../../components/WarningMessage";
import { useResponsive } from '../../../contexts/responsive';
import { useProgress } from "../../../contexts/progress";
import serviceMethods from "../../../service"
import ChooseDateIntakeForm from "../../../components/ChooseDateIntakeForm/ChooseDateIntakeForm";
import "./index.scss";

const Intake4 = () => {
  const { jobDetails, setJobDetails } = useIntakeForm()
  const { navigateNext, navigatePrevious } = useProgress();
  //comment out some old logic for disabling dates on the calendar.  It was originally built in based on the figma design but we haven't used it so far.  The disabled Dates table in the database is empty.  So right now it's not doing anything but it can be restored later if we want.
  // const [disabledDates, setDisabledDates] = useState([]);

  // const getDisabledDates = async () => {
  //   const res = await serviceMethods.getDisabledDates();
  //   if (res.ok){
  //     const result = await res.json()
  //     const dates = result.map(item => item.date);
  //     setDisabledDates(dates);
  //   }
  //   setIsFetchingDisabledDates(false)
  // }

  // const extractedDays = disabledDates?.map(dateString => {
  //   const date = new Date(dateString);
  //   return {
  //     month: date.getMonth() + 1,
  //     day: date.getDate(),
  //     year: date.getFullYear()
  //   }
  // });

  //     getDisabledDates()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const res = await serviceMethods.updateRequestedDate(jobDetails)
    if (res.ok) {
      navigateNext()
    }
  }

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const checkForEmptyRequestedDate = () => {
    for (let i = 0; i < jobDetails.length; i++){
      if (!jobDetails[i].requestedDate || jobDetails[i].requestedDate === "") {
        setButtonDisabled(true);
        return;
      }
    }
    setButtonDisabled(false);
  }

  useEffect(()=>{
    checkForEmptyRequestedDate(jobDetails);
  },[jobDetails])

  const { isMobileView } = useResponsive();

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className="lg:w-1/3">
        {isMobileView ?
          <ProgressPaneMobile />
          :
          <ProgressPaneDesktop />
        }
      </div>
      <div className={`p-6 lg:p-20 flex flex-col gap-12 lg:w-full md:mb-0 main-container-custom
        ${isMobileView &&
        'gunite_intake_4_content_container_mobile'
        }
      `}>

        {/* this div hidden in mobile */}
        <div className="hidden lg:flex flex-col gap-6">

          <button onClick={() => { navigatePrevious() }}>
            <div className="flex gap-2 items-center">
              <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
              <p className="font-semibold text-gunite-red">Back</p>
            </div>
          </button>
          <p className="text-secondary-blue font-bold text-4xl mb-1">Schedule</p>
          <WarningMessage />
        </div>

        {jobDetails.length > 1 ? (
          <>
            {jobDetails.map((job, i) => (
              <ChooseDateIntakeForm
                job={job}
                i={i}
                key={`jobKey${i}`}
                jobDetails={jobDetails}
                setJobDetails={setJobDetails}
                // extractedDays={extractedDays}
              />
            ))}
          </>
          ) : (
            <ChooseDateIntakeForm
              job={jobDetails[0]}
              i={0}
              key={`jobKey${0}`}
              jobDetails={jobDetails}
              setJobDetails={setJobDetails}
              // extractedDays={extractedDays}
            />
          )
        }

        <button className={`w-full lg:w-auto lg:px-9 lg:py-3 rounded p-3 text-2xl font-bold ${buttonDisabled ? "bg-black/10 text-black/40" : "bg-gunite-red text-white"}`}
          disabled={buttonDisabled}
          onClick={handleSubmit}
        >Continue</button>
      </div>
    </div>
  )
}

export default Intake4
