import React, { useState, useEffect } from 'react'
import { GoogleMap, Marker, InfoWindowF } from '@react-google-maps/api';
import { mapOptions } from './MapConfiguration';
import { useLocation } from 'react-router-dom';
import { useProgress } from '../../contexts/progress';
import { useIntakeForm } from '../../contexts/intakeForm';
import cookies from 'browser-cookies';
import serviceMethods from '../../service';
import { asrBranches } from '../../utils';

function Map(props) {
  const { pathname } = useLocation();
  const { builderInfo, setBuilderInfo, retrievedLocationsGunite, retrievedLocationsPlaster } = useIntakeForm();
  const {
    navigateNext,
    currentService,
    currentPosition,
    setCurrentPosition,
    skipASRChoice,
    setSkipASRChoice,
  } = useProgress();
  const apiUrl = process.env.REACT_APP_API_URL;

  const [dimensions, setDimensions] = useState({
    width: props.mapContainer.current?.offsetWidth,
    height: props.mapContainer.current?.offsetHeight
  })

  const { renderMarkers, coordinates, handleMarkerClick, handleMarkerInfoClose, selectedMarker } = props

  useEffect(()=>{
    function windowResized() {
      const width = props.mapContainer.current?.offsetWidth || 835;
      const height = props.mapContainer.current?.offsetHeight;
      setDimensions({ width, height });
    }
    window.addEventListener('resize',windowResized)
    windowResized()
    return ()=>{
      window.removeEventListener('resize',windowResized)
    }
  },[props.mapContainer.current])

  const [center, setCenter] = useState({
    lat: coordinates.newLat || 29.834960,
    lng: coordinates.newLng || -97.904540,
  });

  useEffect(()=>{
    setCenter({
      lat: coordinates.newLat || 29.834960,
      lng: coordinates.newLng || -97.904540,
    })
  },[coordinates])

  const handleNoGuniteMix = () => {
    if (selectedMarker) {
      const updatedMarker = selectedMarker;
      cookies.set('branchId', `${updatedMarker.id}`, { expires: 400 })
      if (!cookies.get('searchZip') || cookies.get('searchZip' === '')){
        cookies.set('searchZip', `${updatedMarker.zip ? updatedMarker.zip : ''}`, { expires: 400 })
      }
    }
    if (currentService === 'gunite') {
      if (asrBranches.includes(selectedMarker.id)){
        const mustSkipASR = false;
        setSkipASRChoice(mustSkipASR);
        navigateNext()
      } else {
        const mustSkipASR = true;
        setSkipASRChoice(mustSkipASR);
        setCurrentPosition(currentPosition + 1);
        navigateNext()
      }
    } else {
      navigateNext()
    }
  }

  const handleUpdateLocation = (locationDetails) => {
    if (builderInfo.id) {
      serviceMethods.updateLocation(locationDetails)
    }
  }

  return renderMarkers && (
    <GoogleMap
      mapContainerStyle={dimensions}
      mapId={mapOptions.mapId}
      center={center}
      zoom={6}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: false,
      }}
    >
      {pathname.includes('gunite/choose-location') ?
        retrievedLocationsGunite.map((location) => (
          <>
            <Marker
              key={location.id}
              position={{ lat: location.latitude, lng: location.longitude }}
              onClick={() => handleMarkerClick(location)}
            />
            {location.locationAddresses && location.locationAddresses.length > 0 && location.locationAddresses.map((locationAddress, index) => (
              <Marker
                key={`${location.id}-${index}`}
                position={{ lat: locationAddress.latitude, lng: locationAddress.longitude }}
                onClick={() => handleMarkerClick({
                  ...locationAddress,
                  label: location.label,
                  id: location.id,
                  email: location.email,
                  phone: location.phone,
                  image: location.image,
                  service: location.service
                })}
              />
            ))}
          </>
        ))
        :
        retrievedLocationsPlaster.map((location) => (
          <>
            <Marker
              key={location.id}
              position={{ lat: location.latitude, lng: location.longitude }}
              onClick={() => handleMarkerClick(location)}
            />
            {location.locationAddresses && location.locationAddresses.length > 0 && location.locationAddresses.map((locationAddress, index) => (
              <Marker
                key={`${location.id}-${index}`}
                position={{ lat: locationAddress.latitude, lng: locationAddress.longitude }}
                onClick={() => handleMarkerClick({
                  ...locationAddress,
                  label: location.label,
                  id: location.id,
                  email: location.email,
                  phone: location.phone,
                  image: location.image,
                  service: location.service
                })}
              />
            ))}
          </>
        ))
      }

      {/*IMPORTANT: do not use InfoWindow component, instead use InfoWindowF to avoid visual bug*/}
      {selectedMarker &&(
        <InfoWindowF
          position={{
            lat: selectedMarker.latitude + 0.35,
            lng: selectedMarker.longitude
          }}
          onCloseClick={handleMarkerInfoClose}
        >
          <div className='flex flex-col justify-between items-center py-3 min-w-[175px]'>
            <img src={`${apiUrl}/${selectedMarker?.image}`} alt="Map location logo" className='w-[94px]' crossOrigin='anonymous'/>
            <p className='text-secondary-blue font-semibold text-base font-robotoSlab my-[5px] text-center'>
              {selectedMarker.label}
            </p>
            <button className='flex justify-center w-2/3 bg-gunite-red font-medium
              text-white font-roboto py-1 px-[10px] rounded'
              onClick={() => {
                setBuilderInfo({
                  ...builderInfo,
                  chosenLocationId: selectedMarker.id.toString(),
                  chosenLocationZip: selectedMarker.zip,
                });
                handleUpdateLocation({
                  id: builderInfo.id,
                  chosenLocationId: selectedMarker.id,
                  chosenLocationZip: selectedMarker.zip,
                })
                handleNoGuniteMix()
              }}
            >CONTINUE
              <div>
                <img src='/white_right_arrow.svg' className='mt-[-1.5px]'/>
              </div>
            </button>
          </div>
        </InfoWindowF>
      )}
      <></>
    </GoogleMap>)
}

export default React.memo(Map)
