import JobDetailsDetailsTabReadable from "../JobDetailsDetailsTabReadable";
import JobDetailsDetailsTabEditable from "../JobDetailsDetailsTabEditable";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";
import { useScheduler } from "../../contexts/scheduler";

const JobDetailsDetailsTab = () => {
  const { selectedJobId, selectedScheduler } = useScheduler()
  const { detailsTabEditing, setDetailsTabEditing, setUpdatedJob, setUpdatedPool, setUpdatedCustomer } = useJobDetailsModal();

  const selectedJob = selectedScheduler.location.jobs.find(
    (item) => item.id === selectedJobId
  );

  const handleClick = () => {
    setUpdatedJob({
      residentialOrCommercial: selectedJob.residentialOrCommercial,
      jobName: selectedJob.jobName,
      address: selectedJob.address,
      city: selectedJob.city,
      state: selectedJob.state,
      zipCode: selectedJob.zipCode,
      gateCode: selectedJob.gateCode,
      notes: selectedJob.notes,
      isHudJob: selectedJob.isHudJob,
      electricityOnSite: selectedJob.electricityOnSite,
      waterOnSite: selectedJob.waterOnSite,
      supervisorFName: selectedJob.supervisorFName,
      supervisorLName: selectedJob.supervisorLName,
      supervisorPhone: selectedJob.supervisorPhone,
      supervisorEmail: selectedJob.supervisorEmail,
      guniteType: selectedJob.guniteType,
      waterproofing: selectedJob.waterproofing
    });

    const pool = selectedJob.pools?.[0];

    setUpdatedPool({
      planUri: pool?.planUri,
      guniteWPlaster: pool?.guniteWPlaster,
      perimeterFt: pool?.perimeterFt,
      perimeterInches: pool?.perimeterInches,
      lengthFt: pool?.lengthFt,
      lengthInches: pool?.lengthInches,
      widthFt: pool?.widthFt,
      widthInches: pool?.widthInches,
      deepEndFt: pool?.deepEndFt,
      deepEndInches: pool?.deepEndInches,
      shallowEndFt: pool?.shallowEndFt,
      shallowEndInches: pool?.shallowEndInches,
      squareFt: pool?.squareFt,
      depth: pool?.depth,
      tanningLedgeFt: pool?.tanningLedgeFt,
      tanningLedgeInches: pool?.tanningLedgeInches,
      benchFt: pool?.benchFt,
      benchInches: pool?.benchInches,
      barstools: pool?.barstools,
      drainCovers: pool?.drainCovers,
      fittings: pool?.fittings,
      steps: pool?.steps,
      spa: pool?.spa,
      basin: pool?.basin,
      raisedBeam: pool?.raisedBeam,
      retainingWalls: pool?.retainingWalls,
      notch: pool?.notch,
      negativeEdge: pool?.negativeEdge,
      newOrReplaster: pool?.newOrReplaster,
      material: pool?.material,
      color: pool?.color,
      estimateNeeded: pool?.estimateNeeded,
      materialAddIns: pool?.materialAddIns,
      poolIsDugOut: pool?.poolIsDugOut,
      plumbingIsDone: pool?.plumbingIsDone,
      weWillPrepPool: pool?.weWillPrepPool,
      waterfall: pool?.waterfall
    });

    setUpdatedCustomer({
      id: selectedJob?.owner?.customer?.id,
      firstName: selectedJob?.owner?.customer?.firstName,
      lastName: selectedJob?.owner?.customer?.lastName,
      bizName: selectedJob?.owner?.customer?.bizName,
      bizAddress: selectedJob?.owner?.customer?.bizAddress
    })

    setDetailsTabEditing(true)
  }
  return (
    <div className="p-4 flex flex-col justify-center align-center">
      {!detailsTabEditing && (
        <>
          <button
            onClick={handleClick}
            className="mt-[8px] p-[8px] py-[5px] rounded-[4px] bg-all-f-white border border-navy-light flex flex-row justify-center items-center w-full"
          >
            <img
              src="/JobDetails/edit.svg"
              alt="Edit"
              className="mr-2 w-[14px]"
            />
            <p className="text-[14px] font-[450] text-dark-blue select-none">
              Edit details
            </p>
          </button>
        </>
      )}

      {detailsTabEditing ? (
        <JobDetailsDetailsTabEditable />
      ) : (
        <JobDetailsDetailsTabReadable />
      )}

      {!detailsTabEditing && (
        <>
          <button
            onClick={handleClick}
            className="mt-[8px] p-[8px] py-[5px] rounded-[4px] bg-all-f-white border border-navy-light flex flex-row justify-center items-center w-full"
          >
            <img
              src="/JobDetails/edit.svg"
              alt="Edit"
              className="mr-2 w-[14px]"
            />
            <p className="text-[14px] font-[450] text-dark-blue select-none">
              Edit details
            </p>
          </button>
        </>
      )}
    </div>
  );
};

export default JobDetailsDetailsTab;
