import React, { useState } from 'react';


const LoadingSpinner = () => {


  return (
    <div
      className='w-6 h-6 flex justify-center items-center overflow-hidden relative animate-spin'
    >
      <div
        className='absolute w-full h-full rounded-[50%] border-4 border-transparent border-l-gunite-red animate-pulse'
      ></div>
      <div
        className='absolute w-full h-full rounded-[50%] border-4 border-transparent border-r-gunite-red animate-pulse'
      ></div>
    </div>
  )
};

export default LoadingSpinner;
