/* External imports */
import isEqual from 'lodash.isequal';
import { useState, useEffect, useRef } from "react";
/* Internal imports */
import { useAdminRates } from "../../contexts/adminRates";
import { useAdminBranchRates } from "../../contexts/adminBranchRates";
import { useAdmin } from '../../contexts/admin'

export default function AdminBranchRates() {
  const [displayText, setDisplayText] = useState({
    changesSaved: false,
    cancelChanges: false,
    resetToDefaults: false
  })
  const { selectedLocation } = useAdmin()
  const firstUpdate = useRef(true);
  const { rate } = useAdminRates();
  const { locationRate, refreshLocationRate, renameGlobalPropertiesToLocationProperties, updateLocationRate } = useAdminBranchRates();

  const generateTempValues = (locationRate) => ({
    locationRateInvoice: {
      pricePerCubicYards: locationRate?.locationRateInvoice?.pricePerCubicYards || 0,
      insuranceRate: locationRate?.locationRateInvoice?.insuranceRate || 0,
      crewTaxRate: locationRate?.locationRateInvoice?.crewTaxRate || 0,
    },
    locationRateGuniteMaterial: {
      cementCostPerYard: locationRate?.locationRateGuniteMaterial?.cementCostPerYard || 0,
      cementConversionRate: locationRate?.locationRateGuniteMaterial?.cementConversionRate || 0,
      sandCostPerYard: locationRate?.locationRateGuniteMaterial?.sandCostPerYard || 0,
      sandConversionRate: locationRate?.locationRateGuniteMaterial?.sandConversionRate || 0,
    },
    locationRateGuniteFuel: {
      crewTruckCostPerGallon: locationRate?.locationRateGuniteFuel?.crewTruckCostPerGallon || 0,
      crewTruckMilesPerGallon: locationRate?.locationRateGuniteFuel?.crewTruckMilesPerGallon || 0,
      mobileMixersCostPerGallon: locationRate?.locationRateGuniteFuel?.mobileMixersCostPerGallon || 0,
      mobileMixersMilesPerGallon: locationRate?.locationRateGuniteFuel?.mobileMixersMilesPerGallon || 0,
      compressorClearCostPerGallon: locationRate?.locationRateGuniteFuel?.compressorClearCostPerGallon || 0,
      compressorClearMilesPerGallon: locationRate?.locationRateGuniteFuel?.compressorClearMilesPerGallon || 0,
      compressorRedCostPerGallon: locationRate?.locationRateGuniteFuel?.compressorRedCostPerGallon || 0,
      compressorRedGallonsPerYard: locationRate?.locationRateGuniteFuel?.compressorRedGallonsPerYard || 0,
    },
    locationRatePlasterFuel: {
      mixerCostPerGallon: locationRate?.locationRatePlasterFuel?.mixerCostPerGallon || 0,
      mixerMilesPerGallon: locationRate?.locationRatePlasterFuel?.mixerMilesPerGallon || 0,
      slurryTruckCostPerGallon: locationRate?.locationRatePlasterFuel?.slurryTruckCostPerGallon || 0,
      slurryTruckMilesPerGallon: locationRate?.locationRatePlasterFuel?.slurryTruckMilesPerGallon || 0,
    }
  });

  const defaultTempValues = generateTempValues(locationRate);

  const [tempValues, setTempValues] = useState(defaultTempValues);

  useEffect(() => {
    if (firstUpdate.current) {
      refreshLocationRate();
      firstUpdate.current = false;
      return;
    }
    if (locationRate) {
      setTempValues(generateTempValues(locationRate));
    }
  }, [locationRate]);

  const onResetToDefaults = () => {
    setDisplayText({
      changesSaved: false,
      cancelChanges: false,
      resetToDefaults: true
    })
    // use global rates
    const renamedRateObject = renameGlobalPropertiesToLocationProperties(rate);
    const globalDefaultTempValues = generateTempValues(renamedRateObject);
    setTempValues(globalDefaultTempValues)
  }

  const checkForChanges = () => {
    if (defaultTempValues) {
      return !isEqual(defaultTempValues, tempValues);
    }
  }

  const onSave = () => {
    setDisplayText({
      changesSaved: true,
      cancelChanges: false,
      resetToDefaults: false
    })
    updateLocationRate(tempValues)
  }


  const onCancel = () => {
    setDisplayText({
      changesSaved: false,
      cancelChanges: true,
      resetToDefaults: false
    })
    setTempValues(defaultTempValues);
  }

  const roundToTwoDecimalPlacesIfNeeded = (number) => {
    const roundedNumber = number.toFixed(2);
    return parseFloat(roundedNumber);
  };

  return (
    <div className="">
      <h1 className="text-xl font-semibold text-dark-blue mb-6">
        Branch rates
      </h1>

      <section
        className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
        <h2 className="text-lg font-semibold text-dark-blue">
          Taxes
        </h2>
        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-3">
            Crew tax rate
          </p>
          <div className="flex flex-col w-3/12">
            <label className="text-dark-blue py-1 text-xs inline-size-max">Tax rate</label>
            <div className="relative w-full">
              <input
                value={tempValues.locationRateInvoice.crewTaxRate}
                onChange={(e) => setTempValues({ ...tempValues, locationRateInvoice: { ...tempValues.locationRateInvoice, crewTaxRate: parseFloat(parseFloat(e.target.value)) } })}
                className="px-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                type="text"
              />
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-dark-blue">%</span>

            </div>
          </div>
        </div>
      </section>

      <section
        className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
        <h2 className="text-lg font-semibold text-dark-blue">
          Insurance
        </h2>
        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-3">
            Insurance rate
          </p>
          <div className="flex flex-col w-3/12">
            <label className="text-dark-blue py-1 text-xs inline-size-max">Insurance rate</label>
            <div className="relative w-full">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
              <input
                value={tempValues.locationRateInvoice.insuranceRate}
                onChange={(e) => {
                  setTempValues({
                    ...tempValues,
                    locationRateInvoice: {
                      ...tempValues.locationRateInvoice,
                      insuranceRate: parseFloat(parseFloat(e.target.value || 0))
                    }
                  })
                }}
                className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                type="number"
              />
            </div>
          </div>
        </div>
      </section>

      {/* GUNITE invoice section */}
      {(selectedLocation.service === 'GUNITE') && (
        <section
          className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
          <h2 className="text-lg font-semibold text-dark-blue">
            Invoice
          </h2>
          <div className="flex flex-row w-full items-end justify-between mb-4">
            <p className="text-dark-blue text-sm mb-3">
              Cubic yards
            </p>
            <div className="flex flex-col w-3/12">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Price</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={tempValues.locationRateInvoice.pricePerCubicYards}
                  onChange={(e) => setTempValues({ ...tempValues, locationRateInvoice: { ...tempValues.locationRateInvoice, pricePerCubicYards: parseFloat(parseFloat(e.target.value)) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                  type="number"
                />
              </div>
            </div>
          </div>
        </section>
      )}

      {/* GUNITE materials section */}
      {(selectedLocation.service === 'GUNITE') && (
        <section
          className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
          <h2 className="text-lg font-semibold text-dark-blue">
            Materials
          </h2>
          <div className="flex flex-row w-full items-end justify-between mb-4">
            <p className="text-dark-blue text-sm mb-2">
              Sand
            </p>
            <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Yard</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={tempValues.locationRateGuniteMaterial.sandCostPerYard}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteMaterial: { ...tempValues.locationRateGuniteMaterial, sandCostPerYard: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Conversion rate</label>
                <div className="relative w-full">
                  <input
                    value={tempValues.locationRateGuniteMaterial.sandConversionRate}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteMaterial: { ...tempValues.locationRateGuniteMaterial, sandConversionRate: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Ton</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={roundToTwoDecimalPlacesIfNeeded(tempValues.locationRateGuniteMaterial.sandCostPerYard * tempValues.locationRateGuniteMaterial.sandConversionRate)}
                    readOnly
                    className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr className="border-navy-light border w-full mb-4" />

          <div className="flex flex-row w-full items-end justify-between mb-4">
            <p className="text-dark-blue text-sm mb-2">
              Cement
            </p>
            <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Yard</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={tempValues.locationRateGuniteMaterial.cementCostPerYard}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteMaterial: { ...tempValues.locationRateGuniteMaterial, cementCostPerYard: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Conversion rate</label>
                <div className="relative w-full">
                  <input
                    value={tempValues.locationRateGuniteMaterial.cementConversionRate}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteMaterial: { ...tempValues.locationRateGuniteMaterial, cementConversionRate: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Ton</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={roundToTwoDecimalPlacesIfNeeded(tempValues.locationRateGuniteMaterial.cementCostPerYard * tempValues.locationRateGuniteMaterial.cementConversionRate)}
                    readOnly
                    className="bg-navy-light bg-opacity-90 pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue text-opacity-80 border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* GUNITE fuel section */}
      {(selectedLocation.service === 'GUNITE') && (
        <section
          className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
          <h2 className="text-lg font-semibold text-dark-blue">
            Fuel
          </h2>

          <div className="flex flex-row w-full items-end justify-between mb-4">
            <p className="text-dark-blue text-sm mb-3">
              Crew truck
            </p>

            <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Miles/Gallon</label>
                <div className="relative w-full">
                  <input
                    value={tempValues.locationRateGuniteFuel.crewTruckMilesPerGallon}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteFuel: { ...tempValues.locationRateGuniteFuel, crewTruckMilesPerGallon: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={tempValues.locationRateGuniteFuel.crewTruckCostPerGallon}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteFuel: { ...tempValues.locationRateGuniteFuel, crewTruckCostPerGallon: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Mile</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={roundToTwoDecimalPlacesIfNeeded(tempValues.locationRateGuniteFuel.crewTruckCostPerGallon / tempValues.locationRateGuniteFuel.crewTruckMilesPerGallon)}
                    readOnly
                    className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr className="border-navy-light border w-full mb-4" />

          <div className="flex flex-row w-full items-end justify-between mb-4">
            <p className="text-dark-blue text-sm mb-2">
              Mobile mixers
            </p>
            <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Miles/Gallon</label>
                <div className="relative w-full">
                  <input
                    value={tempValues.locationRateGuniteFuel.mobileMixersMilesPerGallon}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteFuel: { ...tempValues.locationRateGuniteFuel, mobileMixersMilesPerGallon: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={tempValues.locationRateGuniteFuel.mobileMixersCostPerGallon}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteFuel: { ...tempValues.locationRateGuniteFuel, mobileMixersCostPerGallon: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Mile</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={roundToTwoDecimalPlacesIfNeeded(tempValues.locationRateGuniteFuel.mobileMixersCostPerGallon / tempValues.locationRateGuniteFuel.mobileMixersMilesPerGallon)}
                    readOnly
                    className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr className="border-navy-light border w-full mb-4" />

          <div className="flex flex-row w-full items-end justify-between mb-4">
            <p className="text-dark-blue text-sm mb-2">
              Compressor clear
            </p>
            <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Miles/Gallon</label>
                <div className="relative w-full">
                  <input
                    value={tempValues.locationRateGuniteFuel.compressorClearMilesPerGallon}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteFuel: { ...tempValues.locationRateGuniteFuel, compressorClearMilesPerGallon: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={tempValues.locationRateGuniteFuel.compressorClearCostPerGallon}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteFuel: { ...tempValues.locationRateGuniteFuel, compressorClearCostPerGallon: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Mile</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={roundToTwoDecimalPlacesIfNeeded(tempValues.locationRateGuniteFuel.compressorClearCostPerGallon / tempValues.locationRateGuniteFuel.compressorClearMilesPerGallon)}
                    readOnly
                    className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr className="border-navy-light border w-full mb-4" />

          <div className="flex flex-row w-full items-end justify-between mb-4">
            <p className="text-dark-blue text-sm mb-2">
              Compressor red
            </p>
            <div className="flex flex-row w-6/12 gap-2 min-[400px]:gap-5">
              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Gallons/Yard</label>
                <div className="relative w-full">
                  <input
                    value={tempValues.locationRateGuniteFuel.compressorRedGallonsPerYard}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteFuel: { ...tempValues.locationRateGuniteFuel, compressorRedGallonsPerYard: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={tempValues.locationRateGuniteFuel.compressorRedCostPerGallon}
                    onChange={(e) => setTempValues({ ...tempValues, locationRateGuniteFuel: { ...tempValues.locationRateGuniteFuel, compressorRedCostPerGallon: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>

        </section>
      )}

      {/* PLASTER fuel section */}
      {(selectedLocation.service === 'PLASTER') && (
        <section
          className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
          <h2 className="text-lg font-semibold text-dark-blue">
            Fuel
          </h2>

          <div className="flex flex-row w-full items-end justify-between mb-4">
            <p className="text-dark-blue text-sm mb-3">
              Slurry truck
            </p>

            <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Miles/Gallon</label>
                <div className="relative w-full">
                  <input
                    value={tempValues?.locationRatePlasterFuel?.slurryTruckCostPerGallon ?? 0}
                    onChange={(e) => setTempValues({ ...tempValues, locationRatePlasterFuel: { ...tempValues.locationRatePlasterFuel, slurryTruckCostPerGallon: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={tempValues?.locationRatePlasterFuel?.slurryTruckMilesPerGallon ?? 0}
                    onChange={(e) => setTempValues({ ...tempValues, locationRatePlasterFuel: { ...tempValues.locationRatePlasterFuel, slurryTruckMilesPerGallon: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Mile</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={roundToTwoDecimalPlacesIfNeeded(tempValues?.locationRatePlasterFuel?.slurryTruckMilesPerGallon ?? 0 / tempValues?.locationRatePlasterFuel?.slurryTruckCostPerGallon ?? 0)}
                    readOnly
                    className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr className="border-navy-light border w-full mb-4" />

          <div className="flex flex-row w-full items-end justify-between mb-4">
            <p className="text-dark-blue text-sm mb-2">
              Mixers
            </p>
            <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Miles/Gallon</label>
                <div className="relative w-full">
                  <input
                    value={tempValues?.locationRatePlasterFuel?.mixerMilesPerGallon ?? 0}
                    onChange={(e) => setTempValues({ ...tempValues, locationRatePlasterFuel: { ...tempValues.locationRatePlasterFuel, mixerMilesPerGallon: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={tempValues?.locationRatePlasterFuel?.mixerCostPerGallon ?? 0}
                    onChange={(e) => setTempValues({ ...tempValues, locationRatePlasterFuel: { ...tempValues.locationRatePlasterFuel, mixerCostPerGallon: parseFloat(e.target.value) } })}
                    className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Mile</label>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    value={roundToTwoDecimalPlacesIfNeeded(tempValues?.locationRatePlasterFuel?.mixerCostPerGallon ?? 0 / tempValues?.locationRatePlasterFuel?.mixerMilesPerGallon ?? 0)}
                    readOnly
                    className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <div className='flex justify-between'>
        <button
          onClick={onResetToDefaults}
          className={`text-sm font-semibold text-all-f-white py-[9px] px-3 bg-dark-blue rounded}`}
        >
          <p className="text-[13px] font-[450] text-all-f-white select-none">
            Reset to defaults
          </p>
        </button>
        <div className='gap-4 flex justify-between'>
          <button
            type="button"
            className={`text-sm font-semibold text-all-f-white py-[9px] px-3 bg-gunite-red rounded ${!checkForChanges() && 'opacity-40'}`}
            onClick={onCancel}
            disabled={!checkForChanges()}
          >
            Cancel
          </button>
          <button
            onClick={onSave}
            type="button"
            className={`text-sm font-semibold text-all-f-white py-[9px] px-3 bg-dark-blue rounded ${!checkForChanges() && 'opacity-40'}`}
            disabled={!checkForChanges()}
          >
            Submit
          </button>
        </div>
      </div>
      <div className='flex pt-6 justify-between'>
        <div className='text-dark-blue font-medium text-xs'>
          {/* {displayText.resetToDefaults && '*Changes have been reset to default'} */}
        </div>
        <div>
          <div className='text-gunite-red font-medium text-xs'>
            {displayText.cancelChanges && '*Changes have been reverted'}
          </div>
          <div className='text-dark-blue font-medium text-xs'>
            {displayText.changesSaved && '*Changes have been saved.'}
          </div>
        </div>
      </div>
    </div>
  )
}
