import React, { useState, useEffect } from 'react';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  parse,
  startOfToday,
} from 'date-fns'
let colStartClasses = [
  'col-start-7',
  '',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
]

const ChooseDateIntakeForm = ({ i, jobDetails, setJobDetails }) => {
  let today = startOfToday()
  const [previouslySelectedDays, setPreviouslySelectedDays] = useState({})

  const updateSelectedDayIfAlreadyChosen = () => {
    const updatedDays = { ...previouslySelectedDays };

    for (let i = 0; i < jobDetails.length; i++) {
      if (jobDetails[i].requestedDate) {
        const day = format(new Date(jobDetails[i].requestedDate), 'd');
        updatedDays[i] = day;
      }
    }
    setPreviouslySelectedDays(updatedDays);
  }

  useEffect(() => {
    updateSelectedDayIfAlreadyChosen()
  }, [])

  let [selectedDay, setSelectedDay] = useState(previouslySelectedDays[i] || today)
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())

  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth)
  })

  const previousMonth = () => {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  const nextMonth = () => {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"))
  }

  const dayClassNames = (day, dayIdx) => {
    //disabled days
    // if (extractedDays.some(dateObj =>
    //   dateObj.day === dayIdx + 1 &&
    //   dateObj.month === numericMonth &&
    //   dateObj.year === day.getFullYear()
    // )) {
    //   return "flex justify-center rounded items-center h-full w-full text-2xl text-black/20 font-bold cursor:not-allowed"
    // } else 
    if (Number(previouslySelectedDays[i]) === dayIdx + 1) {
      //selected day
      return "flex justify-center rounded items-center h-full w-full border-2 border-tertiary-blue bg-tertiary-blue/20 hover:bg-tertiary-blue/30 text-2xl font-bold"
    } else {
      //default days
      return "flex justify-center rounded items-center h-full w-full bg-tertiary-blue/5 hover:bg-tertiary-blue/30 text-2xl font-bold"
    }
  }

  return (
    <>
      {/* Calendar */}
      <div className="flex flex-col gap-1">
        {/* Calendar header row */}
        <div className="flex flex-col gap-6 text-[23px]">
          {`${jobDetails.length > 1 ?
            `Choose Date for Job #${i + 1}: ${jobDetails[i].jobName}`
            :
            ""
            }`}
        </div>
        <div className="flex justify-between items-center">
          <h2 className="text-secondary-blue text-2xl lg:text-4xl font-bold">{format(firstDayCurrentMonth, 'MMMM yyyy')}</h2>
          <div className="flex gap-4">
            <button type='button' onClick={previousMonth}><img alt="previous month" src="/vectors/Calendar_left_chevron.svg" /></button>
            <button type='button' onClick={nextMonth}><img alt="next month" src="/vectors/Calendar_right_chevron.svg" /></button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-7 text-center">
        <div className="font-semibold text-black/20">Mon</div>
        <div className="font-semibold text-black/20">Tue</div>
        <div className="font-semibold text-black/20">Wed</div>
        <div className="font-semibold text-black/20">Thu</div>
        <div className="font-semibold text-black/20">Fri</div>
        <div className="font-semibold text-black/20">Sat</div>
        <div className="font-semibold text-black/20">Sun</div>
      </div>
      <div className="mt-1 grid grid-cols-7 gap-1">
        {days.map((day, dayIdx) => ( //each 'day' is a date object, 'dayIdx' is an integer starting at 0
          <div key={dayIdx}
            //The following ternary operator places the first day of the month on the appropriate day of the calendar 
            className={`h-12 rounded ${dayIdx === 0 && colStartClasses[getDay(day)]}`}>
            <button
              type="button"
              className={dayClassNames(day, dayIdx)}
              onClick={() => {
                setSelectedDay(day)
                const updatedDayIdx = dayIdx + 1;
                setPreviouslySelectedDays(prevState => ({
                  ...prevState,
                  [i]: updatedDayIdx.toString()
                }))
                const newJob = {
                  ...jobDetails[i],
                  requestedDate: new Date(format(day, 'MM-dd-yyyy'))
                }
                const newJobDetails = [
                  ...jobDetails.slice(0, i),
                  newJob,
                  ...jobDetails.slice(i + 1)
                ]
                setJobDetails(newJobDetails);
              }}
            // disabled={extractedDays.some(dateObj => dateObj.day === dayIdx + 1
            //   && dateObj.month === numericMonth
            // )}
            >
              <time>
                {format(day, 'd')}
              </time>
            </button>
          </div>
        ))}
      </div>

      <div className="flex">
        <input type="checkbox" className='mt-[5px]' id='alerts'>
        </input>
        <label className="text-secondary-blue text-opacity-60 ml-[10px]" htmlFor="alerts">
          I want alerts (ie: job changes, etc)
        </label>
      </div>
    </>
  )
}

export default ChooseDateIntakeForm;
