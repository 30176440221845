import React from 'react'
import ProgressPaneMobile from '../../../components/ProgressPaneMobile';
import ProgressPaneDesktop from '../../../components/ProgressPaneDesktop';
import { useIntakeForm } from '../../../contexts/intakeForm'
import PoolDetailsForm from '../../../components/PoolDetailsForm'
import { useProgress } from "../../../contexts/progress";
import { useResponsive } from '../../../contexts/responsive';
import serviceMethods from "../../../service"
import "./index.scss";
import Trashcan from "../../../components/Trashcan";

const PlasterIntake3b = () => {
  const { navigateNext, navigatePrevious } = useProgress();
  const { poolDetails, setPoolDetails, jobDetails } = useIntakeForm()
  const { isMobileView } = useResponsive();

  const handleSubmit = async (e) => {
    e.preventDefault()
    const jobId = jobDetails.id;
    const poolDetailsArray = Array.isArray(poolDetails) ? poolDetails.map(({ jobId, ...rest }) => rest) : [];
    const data = {
      poolDetails: poolDetailsArray,
      jobId,
    };
    const res = await serviceMethods.updatePoolsPlaster3b(data)
    if (res.ok) {
      const responseData = await res.json();
      const updatedPoolDetails = responseData.map((response, index) => ({
        ...poolDetailsArray[index],
        id: response.id,
      }));
      setPoolDetails(updatedPoolDetails);
      navigateNext()
    }
  }

  const errorChecker = () => {
    let error = false
    if (Array.isArray(poolDetails)) {
      poolDetails.forEach((pool) => {
        if (!pool.deepEndFt
          ||!pool.deepEndInches
          || !pool.shallowEndFt
          || !pool.shallowEndInches
          || !pool.lengthFt
          || !pool.lengthInches
          || !pool.widthFt
          || !pool.widthInches
          || !pool.tanningLedgeFt
          || !pool.tanningLedgeInches
          || !pool.benchFt
          || !pool.benchInches
          || !pool.steps
          || !pool.waterfall
          || !pool.barstools
          || !pool.squareFt
          || !pool.perimeterFt
          || !pool.perimeterInches
          || !pool.drainCovers
          || !pool.fittings
        ) {
          error = true
        }
      })
    }
    return error
  }

  const continueButtonVisibility = () => {
    let isVisible = true
    if (Array.isArray(poolDetails)) {
      poolDetails.forEach((pool) => {
        if (!pool?.planUri) {
          isVisible = false
        }
      })
    }
    return isVisible
  }


  return (
    <div className='flex flex-col lg:flex-row'>
      <div className="lg:w-1/3">
        {isMobileView ?
          <ProgressPaneMobile />
          :
          <ProgressPaneDesktop />
        }
      </div>
      <div className={`p-6 lg:p-20 flex flex-col gap-12 lg:w-full
        ${isMobileView &&
        'plaster_intake_3b_content_container_mobile'
        }
      `}>
        {/* Note: this div hidden in mobile */}
        <div className="hidden lg:flex flex-col gap-6">
          <button onClick={() => { navigatePrevious() }}>
            <div className="flex gap-2 items-center">
              <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
              <p className="font-semibold text-gunite-red">Back</p>
            </div>
          </button>
          <div className="flex flex-col gap-1">
            <p className="text-secondary-blue font-bold text-4xl">Job site information</p>
            <p className="text-secondary-blue/60 font-semibold text-base">
              Information about job site, address, pool plan, and job site supervisor.
            </p>
          </div>
          <div className="flex gap-[2px] items-center">
            {/* progress bar */}
            <div className="h-2 w-1/5 bg-primary-light" />
            <div className="h-2 w-1/5 bg-gunite-red" />
            <div className="h-1 w-1/5 bg-black/10" />
            <div className="h-1 w-1/5 bg-black/10" />
          </div>
        </div>

        {Array.isArray(poolDetails) ? poolDetails.map((pool, i) => (
          <div key={`jobDetailsForm${i}`}>
            {jobDetails.length > 1 ?
              (
                <>
                  <div className="text-secondary-blue text-xl font-semibold">
                      Job #{i+1}: {`${jobDetails[i].jobName}`}
                    </div>
                  <Trashcan
                    i={i}
                  />
                </>
              ) : ''
            }
            <PoolDetailsForm
              pool={pool}
              i={i}
              setPoolDetails={setPoolDetails}
              poolDetails={poolDetails}
              jobDetails={jobDetails}
              isGunite={false}
              key={`pool-details-${i}`}
            />
          </div>
        )) : null}

        <button
          className={`w-full rounded p-3 lg:py-3 lg:px-9 lg:w-auto self-start text-2xl font-bold
          ${continueButtonVisibility() ? '' : 'hidden'}
          ${errorChecker() ? 'bg-black/10 text-black/40 cursor-not-allowed' : 'bg-gunite-red text-white  cursor-pointer'}`}
          disabled={errorChecker()}
          onClick={handleSubmit}
          >Continue
        </button>
      </div>
    </div>
  )
}

export default PlasterIntake3b
