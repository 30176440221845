import React from 'react'
import { useScheduler } from '../../contexts/scheduler'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'
import Crew from '../Crew'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud, faDownload } from '@fortawesome/free-solid-svg-icons';

const CalendarDay = (props) => {
  const { interval, displayMode, generatePDF, showUploadModal, setShowUploadModal, setShowDownloadModal, setSelectedDay } = useSchedulerCalendar()
  const { calVisibleCrews } = useScheduler()
  const { day, dayIdx, dayJobs } = props //day is a Date object

  const handleOpenUploadModal = (e) => {
    e.preventDefault()
    setSelectedDay(day)
    generatePDF(day, dayJobs)
    setShowUploadModal(true)
  }

  const handleOpenDownloadModal = (e) => {
    e.preventDefault()
    setSelectedDay(day)
    generatePDF(day, dayJobs)
    setShowDownloadModal(true)
  }

  const crewJobsFilter = (i) => {
    return dayJobs?.filter((calendarJob) => (
      calendarJob?.crews[0]?.crew.id === calVisibleCrews[i].id //this compares ids of calendarJobCrews and crews
    ))
  }

  const dayStrings = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ]

  let colStartClasses = [
    '',
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6'
  ]

  const calendarDayDisplay = () => {
    if (interval === 'week' && displayMode === 'vertical') {
      return (
        <div className='flex flex-col h-full gap-1 p-1 bg-white rounded-lg' key={`calendarDay${day.toUTCString()}`}>
          <div className='flex justify-between'>
            <div className='flex gap-2 pl-2'>
              <p className='font-bold text-navy text-sm w-[18px]'>{day.getUTCDate()}</p>
              <p className='font-normal text-sm text-gray-blue'>{dayStrings[day.getUTCDay()]}</p>
            </div>
            <div className='flex gap-2'>
              <button
                className={`text-xs font-normal border px-1 py-0.5 mb-1 rounded w-6 ${true ? 'text-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                onClick={(e) => {
                  handleOpenDownloadModal(e)
                }}
              >
                <FontAwesomeIcon icon={faDownload} />
              </button>
              <button
                className={`text-xs font-normal border px-1 py-0.5 mb-1 rounded w-6 ${true ? 'text-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                onClick={(e) => {
                  handleOpenUploadModal(e)
                }}
              >
                <FontAwesomeIcon icon={faCloud} />
              </button>
            </div>
          </div>
          <div className='flex flex-row gap-1 h-full overflow-x-auto'>
            {calVisibleCrews?.map((crew, i) => <Crew crew={crew} day={day} key={`crew-${i}`} crewJobs={crewJobsFilter(i)} />)}
          </div>
        </div>
      )
    } else if (interval === 'week' && displayMode === 'horizontal') {
      return (
        <div className='flex flex-col w-full p-1 rounded-lg bg-white'>
          <div className='flex justify-between'>
            <div className='flex gap-2 pl-2'>
              <p className='font-bold text-navy text-sm w-[18px]'>{day.getUTCDate()}</p>
              <p className='font-normal text-sm text-gray-blue'>{dayStrings[day.getUTCDay()]}</p>
            </div>
            <div className='flex gap-2'>
              <button
                className={`text-xs font-normal border px-1 py-0.5 mb-1 rounded w-6 ${true ? 'text-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                onClick={(e) => {
                  handleOpenDownloadModal(e)
                }}
              >
                <FontAwesomeIcon icon={faDownload} />
              </button>
              <button
                className={`text-xs font-normal border px-1 py-0.5 mb-1 rounded w-6 ${true ? 'text-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                onClick={(e) => {
                  handleOpenUploadModal(e)
                }}
              >
                <FontAwesomeIcon icon={faCloud} />
              </button>
            </div>
          </div>
          <div className='flex flex-col gap-1 overflow-y-auto'>
            {calVisibleCrews?.map((crew, i) => <Crew crew={crew} day={day} key={`crew-${i}`} crewJobs={crewJobsFilter(i)} />)}
          </div>
        </div>
      )
    } else if (interval === 'month' && displayMode === 'vertical') {
      return (
        <div className={`flex flex-col gap-1 p-1 h-full rounded-lg bg-white ${dayIdx === 0 && colStartClasses[day.getUTCDay()]}`}>
          <div className='flex justify-between'>
            <p className='text-sm font-bold text-dark-blue'>{day.getUTCDate()}</p>
            <div className='flex gap-2'>
              <button
                className={`text-xs font-normal border px-1 py-0.5 mb-1 rounded w-6 ${true ? 'text-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                onClick={(e) => {
                  handleOpenDownloadModal(e)
                }}
              >
                <FontAwesomeIcon icon={faDownload} />
              </button>
              <button
                className={`text-xs font-normal border px-1 py-0.5 mb-1 rounded w-6 ${true ? 'text-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                onClick={(e) => {
                  handleOpenUploadModal(e)
                }}
              >
                <FontAwesomeIcon icon={faCloud} />
              </button>
            </div>
          </div>
          <div className='flex gap-1 h-full overflow-x-auto'>
            {calVisibleCrews?.map((crew, i) => <Crew crew={crew} day={day} key={`calendar-day-crew-${i}`} crewJobs={crewJobsFilter(i)} />)}
          </div>
        </div>
      )
    } else if (interval === 'month' && displayMode === 'horizontal') {
      return (
        <div className={`flex flex-col gap-1 p-1 h-full rounded-lg bg-white ${dayIdx === 0 && colStartClasses[day.getUTCDay()]}`}>
          <div className='flex justify-between'>
            <p className='text-sm font-bold text-dark-blue'>{day.getUTCDate()}</p>
            <div className='flex gap-2'>
              <button
                className={`text-xs font-normal border px-1 py-0.5 mb-1 rounded w-6 ${true ? 'text-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                onClick={(e) => {
                  handleOpenDownloadModal(e)
                }}
              >
                <FontAwesomeIcon icon={faDownload} />
              </button>
              <button
                className={`text-xs font-normal border px-1 py-0.5 mb-1 rounded w-6 ${true ? 'text-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                onClick={(e) => {
                  handleOpenUploadModal(e)
                }}
              >
                <FontAwesomeIcon icon={faCloud} />
              </button>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            {calVisibleCrews?.map((crew, i) => <Crew crew={crew} day={day} key={`calendar-day-crew-${i}`} crewJobs={crewJobsFilter(i)} />)}
          </div>
        </div>
      )
    }
  }

  return (
    <>{calendarDayDisplay()}</>
  )
}

export default CalendarDay
