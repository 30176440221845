import React, { useEffect, useRef } from "react";
import { useSchedulerCalendar } from "../../contexts/schedulerCalendar";
import { useScheduler } from "../../contexts/scheduler";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";


function CalendarJobCardFlash({ job, color }) {
  const { selectedScheduler, setSelectedJobId } = useScheduler()
  const { isDragging, handleDragStartFlash, handleDragEndFlash, interval } = useSchedulerCalendar()
  const { setShowJobDetailsModal } = useJobDetailsModal()

  const jobCardRef = useRef()

  useEffect(() => {
    const jobCard = jobCardRef.current
    jobCard.addEventListener('dragstart', handleDragStartFlash)

    jobCard.addEventListener('dragend', handleDragEndFlash)
    return () => {
      jobCard.removeEventListener('dragstart', handleDragStartFlash)

      jobCard.removeEventListener('dragend', handleDragEndFlash)
    }
  }, [selectedScheduler])

  const calendarJobFlashDisplay = () => {
    if (interval === 'week') {
      return (
        <div
          ref={jobCardRef}
          className={`draggable flex h-full w-full pl-1 justify-between items-center mb-[1px] basis-7 ${color} bg-opacity-60 rounded text-dark-blue text-[12px] leading-tight shadow border border-dark-blue ${isDragging ? '' : `group hover:bg-opacity-80`}`}
          draggable='true'
          data-job={JSON.stringify(job)}
          onClick={async (e) => {
            setSelectedJobId(job.jobId)
            setShowJobDetailsModal(true)
          }}
        >
          <p className="font-extrabold leading-5">{job?.job?.jobName.toUpperCase()}</p>
          <div className="flex items-center bg-dark-blue text-white px-1 h-full min-h-[20px]">
            <p className="font-extrabold">F</p>
          </div>
        </div>
      )
    } else {
      return (
        <div
          ref={jobCardRef}
          className={`draggable flex justify-between gap-1 h-full w-full ${color} bg-opacity-60 pl-1 rounded text-dark-blue border border-dark-blue text-[12px] leading-tight ${isDragging ? '' : `group hover:bg-opacity-80`}`}
          draggable='true'
          data-job={JSON.stringify(job)}
          onClick={async (e) => {
            setSelectedJobId(job.jobId)
            setShowJobDetailsModal(true)
          }}
        >
          <p className="font-extrabold leading-5">{job?.job?.jobName.toUpperCase()}</p>
          <div className="flex items-center bg-dark-blue text-white px-1 h-min-content">
            <p className="font-extrabold">F</p>
          </div>
        </div>
      )
    }
  }

  return (
    <>{calendarJobFlashDisplay()}</>
  )
}

export default CalendarJobCardFlash;
