import { useEffect, useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { useScheduler } from "../../contexts/scheduler";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";
import ConfirmPopup from "../ConfirmPopup";
import { useAuth } from "../../contexts/auth";
import { useModal } from "../../contexts/modal";
import service from "../../service";

const JobDetailsTransferJobDropdown = () => {
  const [showTransferJobDropdown, setShowTransferJobDropdown] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [searchTerm, setSearchTerm] = useState('');
  const [displayLocations, setDisplayLocations] = useState([]);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const { me, setMe } = useAuth()
  const { modalMethods } = useModal()

  const inputRef = useRef(null);
  const dropdownWrapperRef = useRef(null);

  const { selectedJobId, selectedScheduler } = useScheduler();
  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId)


  const { setNestedModalOpen, locations, getLocationsAsync, setShowJobDetailsModal } = useJobDetailsModal();

  const onConfirm = async () => {
    const jobDetails = {
      jobId: selectedJobId,
      activityType: 'jobTransferred',
      performedById: me.id,
      metadata: {
        newLocationLabel: selectedLocation.label
      },
      locationId: selectedLocation.id
    }
    const res = await service.updateJobLocation(jobDetails)
    if (res.ok) {
      const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
      const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === selectedJobId)
      setMe({
        ...me,
        scheduler: [
          ...me.scheduler.slice(0, selectedSchedulerIndex),
          {
            ...selectedScheduler,
            location: {
              ...selectedScheduler.location,
              jobs: [
                ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
              ]
            }
          },
          ...me.scheduler.slice(selectedSchedulerIndex + 1)
        ]
      })
      setShowJobDetailsModal(false)
      setShowConfirmPopup(false)
      setShowTransferJobDropdown(false)
      setNestedModalOpen(false)
    }
  }

  const onCancel = () => {
    setShowConfirmPopup(false)
    setShowTransferJobDropdown(false)
    setNestedModalOpen(false)
  }

  useEffect(() => {
    getLocationsAsync()
    setSelectedLocationId(selectedJob.locationId)
  }, [])

  useEffect(() => {
    setDisplayLocations(locations);
  }, [locations])

  const handleTransferJob = () => {
    setShowConfirmPopup(true)
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  }

  useEffect(() => {
    setSelectedLocation(locations.find(location => location.id === selectedLocationId))
  }, [selectedLocationId])

  useEffect(() => {
    setDisplayLocations(locations.filter(location => location.label.toLowerCase().includes(searchTerm.toLowerCase())))
  }, [searchTerm])


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownWrapperRef.current && !dropdownWrapperRef.current.contains(event.target)) {
        setShowTransferJobDropdown(false);
        setShowConfirmPopup(false)
        setNestedModalOpen(false)
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const noJobTransfersModal = () => {
    return (
      <div className="flex flex-col items-center gap-2 bg-white py-4 px-10 font-roboto rounded">
        <p>Jobs that are already scheduled or completed may not be transferred.</p>
        <button
          className="text-white font-bold bg-dark-blue px-2 py-1 rounded"
          onClick={() => modalMethods.close()}
        >OK</button>
      </div>
    )
  }


  return (
    <div ref={dropdownWrapperRef} >
      <button
        className="ml-2 flex items-center justify-center h-full w-fit p-[8px] py-[5px] rounded-[4px] bg-all-f-white border border-navy-light"
        onClick={(e) => {
          e.preventDefault()
          if (selectedJob.scheduledAt) {
            modalMethods.setContent(noJobTransfersModal())
            modalMethods.open()
          } else {
            setShowTransferJobDropdown(!showTransferJobDropdown)
            setNestedModalOpen(true)
          }
        }}
      >
        <img
          src="/JobDetails/outpatient.svg"
          alt="transfer"
          className="mr-1.5 select-none w-[17px]"
        />
        <p className="text-[14px] font-[450] text-dark-blue select-none ">Transfer</p>
      </button>

      <div
        className={`w-[350px] right-3 absolute flex flex-col justify-between p-[8px] bg-all-f-white border border-navy-light rounded-[8px] shadow-md text-[14px] ${showTransferJobDropdown ? '' : 'hidden'}`}
      >
        <div className="flex bg-white rounded-lg flex-row items-center border border-navy-light overflow-hidden">
          <FaSearch className="ml-3 text-gray-blue w-[16px]" />
          <input
            ref={inputRef}
            onChange={handleSearchChange}
            value={searchTerm}
            placeholder="Search"
            type="text"
            className="p-[6px] flex-1 text-dark-blue border-none focus:ring-0"
          />
        </div>
        <div className="overflow-y-scroll h-[300px] w-full">
          {displayLocations.map((location) => (
            <div
              key={location.id}
              className='flex items-center w-[285px] h-[32px] p-[6px]'
              onClick={(e) => {
                e.preventDefault()
                setSelectedLocationId(location.id)
              }}
            >
              <img src={selectedLocationId == location.id ? '/PlannerColumn/radio_checked_icon.svg' : '/PlannerColumn/radio_unchecked_icon.svg'} alt='search' className='h-[16px] w-[16px] select-none' />
              <p className='pl-[10px] select-none'>
                {location.label}
              </p>
            </div>
          ))}
        </div>

        <button
          className="flex-1 flex items-center justify-center h-full p-[8px] py-[5px] rounded-[4px] bg-all-f-white border border-navy-light"
          onClick={(e) => {
            e.preventDefault()
            if (selectedLocation) {
              handleTransferJob(selectedLocation)
            }
          }}
        >
          <img
            src="/JobDetails/outpatient.svg"
            alt="transfer"
            className="mr-1.5 select-none w-[17px]"
          />
          <p className="text-[14px] font-[600] select-none ">Transfer job</p>
        </button>

      </div>
      {showConfirmPopup &&
        <ConfirmPopup
          header={"Transfer job"}
          description={`Are you sure you want to transfer this job to ${selectedLocation.label}?`}
          confirmButtonText={"Transfer"}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      }
    </div>
  )
}

export default JobDetailsTransferJobDropdown
