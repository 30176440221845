import React, { useState, useEffect, useRef } from 'react'
import { useScheduler } from "../../contexts/scheduler";
import serviceMethods from '../../service'
import LoadingSpinner from '../LoadingSpinner'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'

const DailyReportModal = () => {
  const { jobLogInstance, breakdownInstance, showUploadModal, setShowUploadModal, selectedDay, setSelectedDay } = useSchedulerCalendar()
  const { selectedScheduler } = useScheduler()
  const [breakdownDisabled, setBreakdownDisabled] = useState(true)
  const [jobLogDisabled, setJobLogDisabled] = useState(true)
  const [breakdownUploading, setBreakdownUploading] = useState(true)
  const [jobLogUploading, setJobLogUploading] = useState(true)
  const [showBreakdownMessage, setShowBreakdownMessage] = useState(false)
  const [showJobLogMessage, setShowJobLogMessage] = useState(false)
  const [breakdownUploadFailed, setBreakdownUploadFailed] = useState(false)
  const [jobLogUploadFailed, setJobLogUploadFailed] = useState(false)

  // send the pdf as a readable stream to the server to upload
  const handleUploadDailyReport = async (isBreakdown) => {

    if (isBreakdown) {
      setBreakdownDisabled(true)
      setBreakdownUploading(true) // turns on loading spinner
    } else {
      setJobLogDisabled(true)
      setJobLogUploading(true)
    }


    const pdfBlob = isBreakdown ? breakdownInstance.blob : jobLogInstance.blob
    const locationString = selectedScheduler?.location.label
    // setting scheduledDate with the first calendarJob in the array

    const breakdownName = isBreakdown ? `Breakdown_${(selectedDay.toISOString()).substring(0,10)}_${locationString}` : ''
    const jobLogName = isBreakdown ? '' : `JobLog_${(selectedDay.toISOString()).substring(0,10)}_${locationString}`

    const fileName = isBreakdown ? breakdownName : jobLogName

    const formData = new FormData()
    formData.append('file', pdfBlob, fileName)
    formData.append('location', locationString)
    formData.append('fileName', fileName)
    formData.append('isBreakdown', isBreakdown)

    const tempResponse = await serviceMethods.uploadDailyPDF(formData)
    const response = await tempResponse.json()

    // turns off loading spinner
    if (isBreakdown) {
      setBreakdownUploading(false)
    } else {
      setJobLogUploading(false)
    }

    if (response.message === 'upload-pdf-successfull') {
      if (isBreakdown) {
        setBreakdownUploadFailed(false)
      } else {
        setJobLogUploadFailed(false)
      }
    } else if (response.message === 'upload-pdf-failure') {
      // server error
      if (isBreakdown) {
        setBreakdownUploadFailed(true)
        setBreakdownDisabled(false)
      } else {
        setJobLogUploadFailed(true)
        setJobLogDisabled(false)
      }
    } else {
      // unknown error
      if (isBreakdown) {
        setBreakdownUploadFailed(true)
        setBreakdownDisabled(false)
      } else {
        setJobLogUploadFailed(true)
        setJobLogDisabled(false)
      }
    }
    if (isBreakdown) {
      setShowBreakdownMessage(true)
    } else {
      setShowJobLogMessage(true)
    }
  }

  const handleCloseModal = () => {
    setSelectedDay(null)
    setShowUploadModal(false)

    // disables buttons
    setBreakdownDisabled(true)
    setJobLogDisabled(true)

    // hides success/fail messages
    setShowBreakdownMessage(false)
    setShowJobLogMessage(false)

   // turn on loading spinners for the next time you open the modal
    setBreakdownUploading(true)
    setJobLogUploading(true)
  }

  useEffect(() => {
    if (showUploadModal) {
      if (!breakdownInstance.loading) {
        setBreakdownDisabled(false)
        setBreakdownUploading(false)
      }
      if (!jobLogInstance.loading) {
        setJobLogDisabled(false)
        setJobLogUploading(false)
      }
    }
  }, [breakdownInstance.loading, jobLogInstance.loading, showUploadModal])

  return (
    <div>
      {!showUploadModal ? <></> : (
        <div
          className='flex font-normal text-base justify-center items-center fixed w-screen h-screen top-0 left-0 bg-navy-light bg-opacity-60 z-10'
          onClick={(e) => {
            e.stopPropagation()
            handleCloseModal()
          }}
        >
          <div
            className="w-[500px] rounded-[4px] bg-all-f-white border border-navy-light p-4 flex flex-col justify-center items-center"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <h2 className="text-lg font-bold text-dark-blue">Upload to OneDrive</h2>
            <p className="text-gray-blue my-4">Daily Reports for: {selectedDay.getUTCMonth() + 1}/{selectedDay.getUTCDate()}/{selectedDay.getUTCFullYear()}</p>
            <div className="flex justify-center gap-4 px-8">

              <button
                onClick={() => {
                  handleCloseModal()
                }}
                className="bg-white hover:bg-gunite-red text-gunite-red hover:text-white rounded-md border border-gunite-red py-2 px-4 mr-2 h-fit"
              >
                Cancel
              </button>

              <div className='flex flex-col'>
                <button
                  className={`flex justify-center border rounded-md py-2 px-4 w-[140px] ${jobLogDisabled ? 'text-opaque-job-details-gray bg-disabled-gray border-opaque-job-details-gray' : 'bg-white text-dark-blue border-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue'}`}
                  onClick={async () => {
                    handleUploadDailyReport(false)
                  }}
                  disabled={jobLogDisabled}
                >{jobLogUploading ? <LoadingSpinner /> : 'Job Logs'}</button>

                <div
                  className={`flex justify-center w-[94%] pt-4 ${showJobLogMessage ? '' : 'hidden'}`}
                >
                  <p
                    className={`${jobLogUploadFailed ? 'text-gunite-red' : 'text-lime-500'}`}
                  >{jobLogUploadFailed ? 'Upload Failed' : 'File Uploaded'}</p>

                </div>

              </div>

              <div className='flex flex-col'>
                <button
                  className={`flex justify-center border rounded-md py-2 px-4 w-[140px] ${breakdownDisabled ? 'text-opaque-job-details-gray bg-disabled-gray border-opaque-job-details-gray' : 'bg-white text-dark-blue border-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue'}`}
                  onClick={async () => {
                    handleUploadDailyReport(true)
                  }}
                  disabled={breakdownDisabled}
                >{breakdownUploading ? <LoadingSpinner /> : 'Breakdowns'}</button>

                <div
                  className={`flex justify-center w-[94%] pt-4 ${showBreakdownMessage ? '' : 'hidden'}`}
                >
                  <p
                    className={`${breakdownUploadFailed ? 'text-gunite-red' : 'text-lime-500'}`}
                  >{breakdownUploadFailed ? 'Upload Failed' : 'File Uploaded'}</p>
                </div>

              </div>


            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default DailyReportModal;
