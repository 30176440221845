import React, { useEffect } from "react";
import ProgressPaneDesktop from "../../../components/ProgressPaneDesktop";
import ProgressPaneMobile from "../../../components/ProgressPaneMobile";
import { useResponsive } from "../../../contexts/responsive";
import { useProgress } from '../../../contexts/progress'
import "./index.scss";
import serviceMethods from "../../../service"
import { useIntakeForm } from "../../../contexts/intakeForm";

const GuniteIntake2 = () => {
  const { jobDetails, setJobDetails } = useIntakeForm()
  const { isMobileView } = useResponsive();
  const { navigateNext, startGunite, skipASRChoice } = useProgress();

  const mixChosen = async (updatedType) => {
    serviceMethods.setGuniteMixTypeCookie(updatedType)
    const newJobDetails = jobDetails.map(job=>({
      ...job,
      guniteType: updatedType,
    }));
    setJobDetails(newJobDetails)
    navigateNext()
  }

  useEffect(()=>{
    if (skipASRChoice === true){
      navigateNext();
    }
  },[skipASRChoice])

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className="lg:w-1/3">
        {isMobileView ?
          <ProgressPaneMobile />
          :
          <ProgressPaneDesktop />
        }
      </div>
      <div className={`p-6 flex flex-col gap-6
          lg:p-20 lg:gap-12
          ${isMobileView &&
            'gunite_intake_2_content_container_mobile'
          }
          `}>

        {/* Note: this div hidden in mobile */}
        <div className="hidden lg:flex flex-col gap-6">
          <button onClick={()=>{startGunite()}}>
            <div className="flex gap-2 items-center" >
              <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
              <p className="font-semibold text-gunite-red">Back</p>
            </div>
          </button>
          <p className="text-secondary-blue font-bold text-4xl">Choose gunite mix</p>
        </div>

        <button onClick={()=>{
          const updatedType = 'ASR Certification'
          mixChosen(updatedType)
        }}>
          <div className="bg-gunite-red rounded flex flex-col gap-2.5 lg:gap-4 p-6 text-white"  >
            <div className="flex gap-2 justify-start items-center">
              <p className="text-2xl lg:text-4xl font-bold">ASR Certified Gunite Mix</p>
              <img alt="verified" src='/vectors/verified.svg' />
            </div>
            <div className="flex justify-between items-end font-roboto text-sm lg:text-base">
              <div>
                <p>Price Per Cubic Yard: $280.00</p>
                <p>Minimum Shoot $6,000.00</p>
                <p>(up to 21 Cubic Yards)</p>
                <p>Spa Charge $500.00</p>
                <p>Peg Board $35.00</p>
              </div>
              <img alt="forward" src='/vectors/arrow_forward.svg' className="h-6 lg:h-9" />
            </div>
          </div>
        </button>
        <button onClick={()=>{
          const updatedType = 'No ASR Certification'
          mixChosen(updatedType)
        }}>
          <div className="bg-non-asr-light-blue rounded flex flex-col gap-2.5 lg:gap-4 p-6 text-white" >
            <div className="flex gap-2 justify-start items-center">
              <p className="text-2xl lg:text-4xl font-bold">Gunite Mix</p>
            </div>
            <div className="flex justify-between items-end font-roboto text-sm lg:text-base">
              <div>
                <p>Price Per Cubic Yard: $251.00</p>
                <p>Minimum Shoot $5,750.00</p>
                <p>(up to 21 Cubic Yards)</p>
                <p>Spa Charge $500.00</p>
                <p>Peg Board $35.00</p>
              </div>
              <img alt="forward" src='/vectors/arrow_forward.svg' className="h-6 lg:h-9" />
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}


export default GuniteIntake2
