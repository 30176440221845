import React, { useState } from "react";
import { usePDF } from '@react-pdf/renderer';
import serviceMethods from '../../service'
import LoadingSpinner from '../LoadingSpinner'

const UploadToOneDriveButton = ({ selectedScheduler, PDFTemplate, selectedJob, selectedCalendarJob, isBreakdown, isEditing = false }) => {
  const [isUploading, setIsUploading] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [uploadFailed, setUploadFailed] = useState(false)
  const [instance, updateInstance] = usePDF({ document: PDFTemplate})

  const handleUpload = async () => {
    // send the pdf as a readable stream to the server to upload
    setIsUploading(true) // turns on loading spinner

    const pdfBlob = instance.blob
    const locationString = selectedScheduler?.location.label

    // setting scheduledDate with the last calendarJob in the array
    // only uses a flashJob date if there are no regular calendarJobs
    let scheduledDate = ''
    if (selectedJob.calendarJobs.length) {
      let lastCalJob = {}
      let lastFlashJob = {}
      for (const job of selectedJob.calendarJobs) {
        if (job.calendarJobType === 'REGULAR') {
          lastCalJob = job
        } else {
          lastFlashJob = job
        }
      }
      scheduledDate = lastCalJob ? lastCalJob.scheduledDate : lastFlashJob.scheduledDate
    }

    const jobName = selectedJob.jobName

    const breakdownName = isBreakdown ? `Breakdown_${scheduledDate.substring(0,10)}_${selectedJob.jobName}_${selectedJob.id}` : ''

    const logType = selectedCalendarJob?.calendarJobType === 'REGULAR' ? 'JobLog' : 'FlashLog'
    const jobLogName = isBreakdown ? '' : `${logType}_${selectedCalendarJob.scheduledDate.substring(0,10)}_${selectedCalendarJob.job.jobName}_${selectedCalendarJob.id}`

    const fileName = isBreakdown ? breakdownName : jobLogName

    const formData = new FormData()
    formData.append('file', pdfBlob, fileName)
    formData.append('jobName', jobName)
    formData.append('location', locationString)
    formData.append('fileName', fileName)
    formData.append('dateString', scheduledDate)

    const tempResponse = await serviceMethods.uploadPDF(formData)
    const response = await tempResponse.json()

    setIsUploading(false) // turns off loading spinner

    if (response.message === 'upload-pdf-successfull') {
      setUploadFailed(false)
    } else if (response.message === 'upload-pdf-failure') {
      // server error
      setUploadFailed(true)
    } else {
      // unknown error
      setUploadFailed(true)
    }
    setShowMessage(true)
  }

  const isDisabled = () => {
    return (isEditing || (isBreakdown ? false : !selectedCalendarJob.jobLog.submittedAt))
  }

  return (
    <div className="">

      <button
        className={`flex justify-center bg-white text-dark-blue border border-dark-blue rounded-md py-2 px-4 w-[180px] ${isDisabled() ? 'text-opaque-job-details-gray bg-disabled-gray border-opaque-job-details-gray' : 'hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue'}`}
        onClick={async () => {
          handleUpload()
        }}
        disabled={isDisabled()}
      >{isUploading ? <LoadingSpinner /> : 'Upload to OneDrive'}</button>

      <div
        className={`flex justify-end w-[94%] pt-4 ${showMessage ? '' : 'hidden'}`}
      >
        <p
          className={`${uploadFailed ? 'text-gunite-red' : 'text-lime-500'}`}
        >{uploadFailed ? 'Upload Failed' : 'File Uploaded'}</p>
      </div>

    </div>
  )
}

export default UploadToOneDriveButton;
