import React, { createContext, useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ListItem from "../components/CustomerJobsListItem";
import ColumnHeaders from "../components/CustomerJobsColumnHeaders";
import { useAuth } from "./auth";
import { getJobStatus, allStatusDisplayOptions } from "../utils";

const RESULTS_PER_PAGE = 15;

const CustomerMyJobsContext = createContext();

const CustomerMyJobsContextProvider = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [displayJobs, setDisplayJobs] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [jobType, setJobType] = useState("All");
  const [allSubmittedJobs, setAllSubmittedJobs] = useState([]);
  const [sortBy, setSortBy] = useState("jobName");
  const [sortAscending, setSortAscending] = useState(true);

  const {
    me: { jobs: allJobs },
  } = useAuth();

  const paginate = (currentPage, jobs) => {
    const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
    const endIndex = startIndex + RESULTS_PER_PAGE;
    let result = jobs.slice(startIndex, endIndex);
    return result;
  };

  function extractStreetName(address) {
    const streetName = address.split(" ").slice(1).join(" ").toLowerCase();
    return streetName;
  }

  const handleColumnHeaderClick = (column) => {
    setSortBy(column);
    if (sortBy === column) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
    }
  }

  const sort = (jobs) => {
    if (sortBy === "jobName") {
      jobs.sort(function (a, b) {
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) {
          return 1;
        }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) {
          return -1;
        }
        if (a.jobType > b.jobType) {
          return 1;
        }
        if (a.jobType < b.jobType) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === "address") {
      jobs.sort((a, b) => {
        const streetNameA = extractStreetName(a.address);
        const streetNameB = extractStreetName(b.address);
        if (streetNameA > streetNameB) return 1;
        if (streetNameA < streetNameB) return -1;
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) return 1;
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) return -1;
        return 0;
      });
    } else if (sortBy === "requestedDate") {
      jobs.sort(function (a, b) {
        if (a.requestedDate > b.requestedDate) {
          return 1;
        }
        if (a.requestedDate < b.requestedDate) {
          return -1;
        }
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) {
          return 1;
        }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === "jobType") {
      jobs.sort(function (a, b) {
        if (a.jobType > b.jobType) {
          return 1;
        }
        if (a.jobType < b.jobType) {
          return -1;
        }
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) {
          return 1;
        }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === "status") {
      jobs.sort(function (a, b) {
        const statusOrder = [
          'Draft', 'Intake', 'Backlog', 'Ready for calendar',
          'Scheduled', 'Completed', 'Archived', 'Deleted'
        ];

        const statusA = getJobStatus(a, allStatusDisplayOptions);
        const statusB = getJobStatus(b, allStatusDisplayOptions);
        const indexA = statusOrder.indexOf(statusA);
        const indexB = statusOrder.indexOf(statusB);

        if (indexA > indexB) {
          return 1;
        }
        if (indexA < indexB) {
          return -1;
        }
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) {
          return 1;
        }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }

    if (!sortAscending) {
      jobs.reverse();
    }

    return jobs;
  };

  useEffect(() => {
    if (Array.isArray(allJobs)) {
      let submittedJobs = allJobs
        .filter(
          (job) =>
            job.submittedAt !== null &&
            getJobStatus(job, allStatusDisplayOptions) != "Archived" &&
            getJobStatus(job, allStatusDisplayOptions) != "Deleted"
        )
        .sort((a, b) => new Date(b.requestedDate) - new Date(a.requestedDate));

      if (searchTerm !== "") {
        submittedJobs = submittedJobs.filter((job) => {
          return (
            job.jobName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            job.address.toLowerCase().includes(searchTerm.toLowerCase())
          );
        });
      }
      if (selectedStatuses.length) {
        submittedJobs = submittedJobs.filter((job) => {
          return selectedStatuses.includes(
            getJobStatus(job, customerMyJobsStatusDisplayOptions)
          );
        });
      }
      if (jobType !== "All") {
        submittedJobs = submittedJobs.filter((job) => {
          return job.jobType.toLowerCase() === jobType.toLowerCase();
        });
      }

      const paginatedJobs = paginate(currentPage, submittedJobs);

      const sortedJobs = sort(paginatedJobs);

      const submittedJobsWTitleTemplate = sortedJobs.map((job) => ({
        ...job,
        displayName: (
          <Link to={`/customer/job-details?id=${job.id}`}>{job.jobName}</Link>
        ),
      }));
      setAllSubmittedJobs(submittedJobs);
      setDisplayJobs(submittedJobsWTitleTemplate);
    }
  }, [sortBy, sortAscending, allJobs, selectedStatuses, jobType, searchTerm, currentPage]);

  const customerMyJobsStatusDisplayOptions = {
    submittedAt: "Received",
    scheduledAt: "Scheduled",
    completedAt: "Completed",
  };

  return (
    <CustomerMyJobsContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        searchTerm,
        setSearchTerm,
        displayJobs, //one page of jobs
        allSubmittedJobs, //all pages
        selectedStatuses,
        setSelectedStatuses,
        jobType,
        setJobType,
        customerMyJobsStatusDisplayOptions,
        allJobs,
        ColumnHeaders,
        sortBy,
        setSortBy,
        sortAscending,
        setSortAscending,
        allSubmittedJobs,
        RESULTS_PER_PAGE,
        ListItem,
        handleColumnHeaderClick,
      }}
    >
      {props.children}
    </CustomerMyJobsContext.Provider>
  );
};

const useCustomerMyJobs = () => useContext(CustomerMyJobsContext);
export { useCustomerMyJobs };

export default CustomerMyJobsContextProvider;
