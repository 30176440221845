import React, { useEffect, useRef } from "react";
import { useScheduler } from "../../contexts/scheduler";
import { useSchedulerCalendar } from "../../contexts/schedulerCalendar";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";

//job here is a calendarJob
function CalendarJobCard({ job, color }) {
  const { setSelectedJobId, selectedScheduler } = useScheduler()
  const { isDragging, handleDragStart, handleDragEnd, interval } = useSchedulerCalendar()
  const { setShowJobDetailsModal } = useJobDetailsModal()

  const jobCardRef = useRef()

  useEffect(() => {
    const jobCard = jobCardRef.current
    jobCard.addEventListener('dragstart', handleDragStart)

    jobCard.addEventListener('dragend', handleDragEnd)
    return () => {
      jobCard.removeEventListener('dragstart', handleDragStart)

      jobCard.removeEventListener('dragend', handleDragEnd)
    }
  }, [selectedScheduler])

  const tags = []
  //tags for all kinds of jobs
  if (job.job?.pools[0]?.newOrReplaster === 'Re-Plaster') {
    tags.push('R')
  }
  if (job.job?.isHudJob) {
    tags.push('H')
  }
  if (job.job?.pools[0]?.spa) {
    tags.push('S')
  }
  //series of tags for plaster only
  if (job.job.jobType === 'plaster') {
    if (job?.job?.pools[0]?.basin) {
      tags.push('B')
    }
  } else { //series of tags for gunite only
    if (job.job?.guniteType === 'ASR Certification') {
      tags.push('A')
    }
    if (job.job?.waterproofing === true) {
      tags.push('W')
    }
    if (job.job?.pools[0]?.negativeEdge) {
      tags.push('NE')
    }
    if (job.job?.pools[0]?.notch) {
      tags.push('N')
    }
    if (job?.job?.pools[0]?.raisedBeam) {
      tags.push('RB')
    }
    if (job?.job?.pools[0]?.retainingWalls) {
      tags.push('RW')
    }
  }

  const stateAbbreviator = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: "IL",
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VI',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY'
  }

  const jobSizeDisplay = () => {
    if (job.job.jobType === 'gunite') {
      // Displays actual yards shot if job logs exist (gunite only)
      let total = 0
      if (job.jobLog) {
        total = (Number(job.jobLog?.yards) || 0)
      }

      return `${total || job.job.estimatedYards} yards`
    } else {
      return `${job.job.estimatedIA} IA`
    }
  }

  const calendarJobDisplay = () => {
    if (interval === 'week') {
      return (
        <div
          ref={jobCardRef}
          className={`draggable flex flex-col justify-between gap-1 mb-[1px]
          basis-1/3 ${color} bg-opacity-60
          w-full p-1 rounded text-dark-blue text-[12px] leading-tight shadow ${isDragging ? '' : `group hover:bg-opacity-80`}`}
          draggable='true'
          data-job={JSON.stringify(job)}
          onClick={async (e) => {
            setSelectedJobId(job.jobId)
            setShowJobDetailsModal(true)
          }}
        >
          <div className="flex flex-col gap-1">
            <p className={`font-extrabold leading-5 ${job.job.completedAt ? 'opacity-50' : ''}`}>{job?.job?.jobName.toUpperCase()}</p>
            <div className="flex flex-col gap-1">
              <p className={`font-medium ${job.job.completedAt ? 'opacity-50' : ''}`}>{job?.job?.address}, {job?.job?.city}, {stateAbbreviator[job?.job?.state]}</p>
              <p className={`font-medium ${job.job.completedAt ? 'opacity-50' : ''}`}>{job.job.owner.customer.bizName}</p>
              {(job.job.jobType === 'plaster' && job?.job?.pools[0]?.material)
                &&
                <p className="font-medium opacity-50">
                  {job?.job?.pools[0]?.material}
                </p>
              }
            </div>
          </div>
          <div className={`flex flex-wrap-reverse gap-1 justify-between ${job.job.completedAt ? 'opacity-50' : ''}`}>
            <div className="px-1 py-[2px] bg-black/10 w-max">
              <p className="">{jobSizeDisplay()}</p>
            </div>
            <div className="flex flex-row gap-1">
              {tags.map((tag, i) => (
                <p key={`tag${i}`} className='bg-black/10 rounded h-fit px-1 py-1 leading-none font-[600]'>
                  {tag}
                </p>))}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          ref={jobCardRef}
          className={`draggable flex flex-col justify-between h-auto w-full ${color} bg-opacity-60 py-1 pl-1 rounded text-dark-blue text-[12px] leading-tight ${isDragging ? '' : `group hover:bg-opacity-80`}`}
          draggable='true'
          data-job={JSON.stringify(job)}
          onClick={async (e) => {
            setSelectedJobId(job.jobId)
            setShowJobDetailsModal(true)
          }}
        >
          <p className={`font-extrabold leading-5 ${job.job.completedAt ? 'opacity-50' : ''}`}>{job?.job?.jobName.toUpperCase()}</p>
        </div>
      )
    }
  }

  return (
    <>{calendarJobDisplay()}</>
  )
}

export default CalendarJobCard;
