import React, { createContext, useContext, useState, useEffect } from 'react';
import ListItem from '../components/SchedulerJobsListListItem';
import ColumnHeaders from '../components/SchedulerJobsListColumnHeaders';
import { useScheduler } from './scheduler';
import { useJobDetailsModal } from './jobDetailsModal'
import DropdownMenu from '../components/SchedulerJobsListDropdownSort'
import { getJobStatus, allStatusDisplayOptions } from '../utils'

const RESULTS_PER_PAGE = 15;

const SchedulerJobsListContext = createContext()

const SchedulerJobsListContextProvider= props => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [displayJobs, setDisplayJobs] = useState([]);
  const [sortBy, setSortBy] = useState('submittedAt');
  const [allSubmittedJobs, setAllSubmittedJobs] = useState([])
  const [plannerJobs, setPlannerJobs] = useState([])
  const [calendarJobs, setCalendarJobs] = useState([])

  const {
    selectedScheduler,
    selectedStatuses,
    setSelectedJobId,
  } = useScheduler();

  const { setShowJobDetailsModal } = useJobDetailsModal();
  const allJobs = selectedScheduler?.location?.jobs || {};

  const paginate = (currentPage, jobs) => {
    const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
    const endIndex = (startIndex + RESULTS_PER_PAGE);
    let result = jobs.slice(startIndex, endIndex);
    return result;
  }

  const sort = (jobs) => {
    if (sortBy === 'submittedAt') {
      jobs.sort(function(a,b) {
        if(b.submittedAt > a.submittedAt) {return  1;}
        if(b.submittedAt < a.submittedAt) {return -1;}
        if(a.jobName.toLowerCase() > b.jobName.toLowerCase()) {return  1;}
        if(a.jobName.toLowerCase() < b.jobName.toLowerCase()) {return -1;}
        return 0;
      });
    } else if (sortBy === 'requestedDate') {
      jobs.sort(function(a,b) {
        if(b.requestedDate > a.requestedDate) {return  1;}
        if(b.requestedDate < a.requestedDate) {return -1;}
        if(a.jobName.toLowerCase() > b.jobName.toLowerCase()) {return  1;}
        if(a.jobName.toLowerCase() < b.jobName.toLowerCase()) {return -1;}
        return 0;
      });
    } else if (sortBy === 'jobName') {
      jobs.sort(function(a,b) {
        if(a.jobName.toLowerCase() > b.jobName.toLowerCase()) {return  1;}
        if(a.jobName.toLowerCase() < b.jobName.toLowerCase()) {return -1;}
        if(a.owner.customer.bizName.toLowerCase() > b.owner.customer.bizName.toLowerCase()) {return  1;}
        if(a.owner.customer.bizName.toLowerCase() < b.owner.customer.bizName.toLowerCase()) {return -1;}
        return 0;
      });
    } else if (sortBy === 'owner.customer.bizName') {
      jobs.sort(function(a,b) {
        if(a.owner.customer.bizName.toLowerCase() > b.owner.customer.bizName.toLowerCase()) {return  1;}
        if(a.owner.customer.bizName.toLowerCase() < b.owner.customer.bizName.toLowerCase()) {return -1;}
        if(a.jobName.toLowerCase() > b.jobName.toLowerCase()) {return  1;}
        if(a.jobName.toLowerCase() < b.jobName.toLowerCase()) {return -1;}
        return 0;
      });
    }

    return jobs
  }

  useEffect(() => {
    if (Array.isArray(allJobs)) {
      let submittedJobs = allJobs.filter(job => job.submittedAt !== null).sort((a,b)=>new Date(b.requestedDate) - new Date(a.requestedDate))

      setAllSubmittedJobs(submittedJobs)

      setPlannerJobs(submittedJobs)

      // setting all calendarJobs for the selected
      const newCalendarJobs = []
      allJobs.forEach((job)=> {
        if (job.calendarJobs[0]) {
          //add all the calJobs for a given job
          newCalendarJobs.push(...job.calendarJobs)
        }
      })
      setCalendarJobs(newCalendarJobs)

      if (searchTerm !== '') {
        submittedJobs = submittedJobs.filter(job => {
          return (
            job.jobName.toLowerCase().includes(searchTerm.toLowerCase())
            || job.address.toLowerCase().includes(searchTerm.toLowerCase())
          )
        })
      }
      if (selectedStatuses.length) {
        submittedJobs = submittedJobs.filter(job => {
          return selectedStatuses.includes(getJobStatus(job, allStatusDisplayOptions))
        })
      }

      const paginatedJobs = paginate(currentPage, submittedJobs)

      const sortedJobs = sort(paginatedJobs)

      const paginatedJobsWTitleTemplate = sortedJobs.map(job => ({
        ...job,
        displayName: (
          <p className="cursor-pointer text-dark-blue text-xl" onClick={()=>{
            setSelectedJobId(job.id)
            setShowJobDetailsModal(true)
          }}>
            {job.jobName}
          </p>
        )
      }))
      setDisplayJobs(paginatedJobsWTitleTemplate)
    }
  },[allJobs, selectedStatuses, searchTerm, currentPage, sortBy])

  return (
    <SchedulerJobsListContext.Provider value = {{
      currentPage, setCurrentPage,
      searchTerm, setSearchTerm,
      displayJobs, //just 1 page
      allSubmittedJobs, //all the pages
      plannerJobs, //all jobs matching
      ColumnHeaders, RESULTS_PER_PAGE,
      sortBy, setSortBy,
      ListItem, DropdownMenu,
      calendarJobs
    }}>
      {props.children}
    </SchedulerJobsListContext.Provider>
  )
}

const useSchedulerJobsList = () => useContext(SchedulerJobsListContext)
export { useSchedulerJobsList }

export default SchedulerJobsListContextProvider
