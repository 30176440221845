import React, { useState, useRef } from 'react'
import _ from 'underscore'

const PhoneInputCustomer = ({ value, placeholder, onChange, isError }) => {
  const [touched, setTouched] = useState(false)
  const inputRef = useRef()

  const uniqueId = `input-${_.uniqueId()}`

  const cursorPosRef = useRef({adjustCursor: false, savedCursorPos: 0})

  const handleKeyDown = (e) => {
    const isNumber = /^[0-9]$/i.test(e.key)
    const isBackspace = e.key === 'Backspace'
    const isDelete = e.key === 'Delete'
    const phoneNumberFull = e.target.value.length === 14
    const isTextSelection = inputRef.current.selectionStart !== inputRef.current.selectionEnd
    const cursorAtEnd = inputRef.current.selectionStart === inputRef.current.value.length

    // only allow keydown if it is a number and (max length not reached or user has highlighted digits)
    if(
      (isNumber && (!phoneNumberFull || isTextSelection)) ||
      (isBackspace && e.target.value.length > 0) ||
      (isDelete && !cursorAtEnd)
    ) {
      // calculate where cursor should be placed after re-render
      handlePhoneCursor(e, (isDelete || isBackspace), cursorAtEnd, isTextSelection)

    } else if(!['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Tab'].includes(e.key)) {

      // prevent keyDown if its not in the array above
      e.preventDefault()
    }
  }

  // function to determine where cursor should go next, taking formatting into consideration
  const handlePhoneCursor = (e, deletion, cursorAtEnd) => {
    let cursorStart = inputRef.current.selectionStart

    if(deletion || !cursorAtEnd) {
      // let the onChange know the cursor will need to be adjusted
      cursorPosRef.current.adjustCursor = true
    }

    // when removing digits with Backspace key, move cursor past formatting characters if needed
    if (e.key === 'Backspace') {
      if(cursorStart === 11) { // (xxx) xxx-x|xxx 11-->9 (xxx) xxx|-xxx
        cursorStart -= 1
      } else if (cursorStart === 6) { // (xxx) |xxx-xxxx 6-->4 (xxx|) xxx-xxxx
        cursorStart -= 1
      } else if (cursorStart === 7){ // (xxx) x|xx-xxxx 7-->4 (xxx|) xxx-xxx
        cursorStart -= 2
      } else if (e.target.value.length === 7 && cursorStart !== 1 && cursorStart !== 5) { // (xxx|) x 4-->2 xx|x  or  (xx|x) x 3-->1 x|xx
        cursorStart -= 1
      }

      // minus one for the character removed
      cursorStart -= 1

    // when removing digits with the Delete key, move cursor past formatting characters if needed
    } else if(e.key === 'Delete') {
      if(inputRef.current.value.length === 7 && [1,2,3].includes(cursorStart)) {
        cursorStart -= 1
      } else if(cursorStart === 0 && inputRef.current.value.length > 6) {
        cursorStart += 1
      } else if(cursorStart === 4) {
        cursorStart += 2
      } else if(cursorStart === 5) {
        cursorStart += 1
      } else if(cursorStart === 9) {
        cursorStart += 1
      }

    } else if(inputRef.current.selectionStart < inputRef.current.value.length) {
      if(cursorStart === 0 && inputRef.current.value.length >= 3) {
        cursorStart += 1
      } else if((cursorStart === 1 || cursorStart === 2) && inputRef.current.value.length === 3) { // x|xx 1-->3 (xx|x) x  and  xx|x 2-->4 (xxx|) x
        cursorStart += 1
      } else if (cursorStart === 4) { // (xxx|) xxx-xxx  4-->7 (xxx) x|xx-xxxx
        cursorStart += 2
      } else if(cursorStart === 5) { // (xxx)| xxx-xxx 5-->7 (xxx) x|xx-xxxx
        cursorStart += 1
      } else if(cursorStart === 9) { // (xxx) xxx|-xxx 9-->11 (xxx) xxx-x|xxx
        cursorStart += 1
      }

      // plus one for the character added
      cursorStart += 1
    }

    // store cursor position so it can be reset after formatting / re-render
    cursorPosRef.current.savedCursorPos = cursorStart
  }

  //function for formatting phone number
  const normalizeInput = (value, previousValue) => {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    //This block is if the user types a new character
    if (!previousValue || value.length > previousValue.length) {

      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
    //this is reached if the user backspaces their entry
    else {

      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  };

  const placeCursor = () => {
    if (cursorPosRef.current.adjustCursor) {
      inputRef.current.selectionStart = inputRef.current.selectionEnd = cursorPosRef.current.savedCursorPos
      cursorPosRef.current.adjustCursor = false
    }
  }

  const focusInput = () => {
    inputRef.current.focus()
  }

  const inputStyles = () => {
    if (!touched || (touched && !isError)) {
      return (
        `peer mt-0 block w-full px-3 pb-2 border-0 border-b focus:ring-0 rounded-t font-semibold bg-tertiary-blue/5 placeholder-transparent text-base border-black focus:border-tertiary-blue pt-6`
      )
    } else {
      return 'peer mt-0 block w-full px-3 pb-2 border-0 border-b focus:ring-0 rounded-t bg-tertiary-blue/5 placeholder-transparent font-semibold text-base border-red-500 pt-6'
    }
  }


  const labelStyles = () => {
    if (!touched || (touched && !isError)) {
      return (
        'absolute left-3 top-2 text-xs font-normal text-black/60 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-4 peer-focus:text-xs peer-focus:top-2 text-black/60 peer-focus:text-tertiary-blue cursor-text'
      )
    } else {
      return (
        'absolute left-3 top-2 text-xs font-normal text-black/60 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-4 peer-focus:text-xs peer-focus:top-2 text-red-500 peer-focus:text-tertiary-blue cursor-text'
      )
    }
  }

  return (
    <div className='group relative w-full'>
      <input
        value={value}
        type='text'
        id={uniqueId}
        placeholder={placeholder}
        onChange={(e) => {
          setTouched(false)
          const newPhone = normalizeInput(e.target.value, value) // normalize the input
          e.target.value = newPhone
          onChange(e) // set phone in builderInfo (setBuilderInfo function passed down as onChange)
          placeCursor()
        }}
        onKeyDown={(e) => {
          handleKeyDown(e)
        }}
        onBlur={() => setTouched(true)}
        className={inputStyles()}
        ref={inputRef}
      />
      <label
        htmlFor={uniqueId}
        className={labelStyles()}
        onClick={focusInput}
      >{placeholder}</label>

    </div>
  )
}


export default PhoneInputCustomer
