import React, { useContext, createContext, useState, useEffect } from 'react';

export const ResponsiveContext = createContext();

const DEFAULT = {
  orientation: 'landscape',
  breakpoint: 'desktop',
  width: window.innerWidth,
  height: window.innerHeight,
};

const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
  xxxl: 1990,
};

const ResponsiveProvider = (props) => {
  const [responsive, setResponsive] = useState(DEFAULT);
  const [isMobileView, setIsMobileView] = useState(true)
  useEffect(() => {
    const updateResponsive = () => {
      const { innerHeight, innerWidth } = window;
      const orientation = innerWidth > innerHeight ? 'landscape' : 'portrait';
      let breakpoint = null;

      if (innerWidth < BREAKPOINTS.lg) {
        breakpoint = 'mobile';
      } else {
        breakpoint = 'desktop';
      }

      setResponsive({
        orientation,
        breakpoint,
        width: innerWidth,
        height: innerHeight,
      });

      setIsMobileView(innerWidth < 1024)
    };

    updateResponsive();
    window.addEventListener('resize', updateResponsive);
    return () => window.removeEventListener('resize', updateResponsive);
  }, []);

  return (
    <ResponsiveContext.Provider value={{ responsive, BREAKPOINTS, isMobileView }}>
      {props.children}
    </ResponsiveContext.Provider>
  );
};

const useResponsive = () => useContext(ResponsiveContext);

export { useResponsive };
export default ResponsiveProvider;
