import React, { useState } from "react";
import moment from 'moment'
import { useAuth } from "../../contexts/auth";
import { useIntakeForm } from "../../contexts/intakeForm";
import { useNavigate, useLocation } from "react-router-dom";
import { useProgress } from "../../contexts/progress";
import cookies from 'browser-cookies';
import { asrBranches } from "../../utils";

const Summary = ({ menuItems, isMobileView, i, isGunite }) => {
  const { builderInfo, jobDetails, poolDetails } = useIntakeForm()
  const { me } = useAuth()
  const { pathname } = useLocation()
  const { setCurrentPosition, pathway } = useProgress()
  const [arrowsClicked, setArrowsClicked] = useState([]);
  let navigate = useNavigate()

  const requestedDate = jobDetails[i]?.requestedDate ? moment(jobDetails[i].requestedDate) : ''
  const displayDate = requestedDate ? requestedDate.format('MMM Do, YYYY') : ''

  function handleShowLessClick(value) {
    setArrowsClicked((prevArrowsClicked) => prevArrowsClicked.filter((item) => item !== value));
  }

  const capitalize = (word) => {
    return word?.charAt(0).toUpperCase() + word?.slice(1)
  }

  const correspondingCurrentPositionGunite = {
    1: 7,
    3: 5,
    4: 3,
    5: 4,
    6: 5,
    7: 6,
  }

  const correspondingCurrentPositionPlaster = {
    1: 6,
    3: 4,
    4: 2,
    5: 3,
    6: 4,
    7: 5,
  }

  const editButtonClick = (key) => {
    if (pathname.includes('gunite')) {
      const routesGunite = {
        1: pathway['gunite'][7],
        3: pathway['gunite'][5],
        4: pathway['gunite'][3],
        5: pathway['gunite'][4],
        6: pathway['gunite'][5],
        7: pathway['gunite'][6]
      }
      setCurrentPosition(correspondingCurrentPositionGunite[key])
      navigate(routesGunite[key])
    } else {
      const routesPlaster = {
        1: pathway['plaster'][6],
        3: pathway['plaster'][4],
        4: pathway['plaster'][2],
        5: pathway['plaster'][3],
        6: pathway['plaster'][4],
        7: pathway['plaster'][5]
      }
      setCurrentPosition(correspondingCurrentPositionPlaster[key])
      navigate(routesPlaster[key])
    }
  }

  return (
    <>
      {Object.entries(menuItems).map(([key, value]) => (
        <div key={key}>
          <div className='flex justify-between text-tertiary-blue font-semibold mb-[10px]'>
            <div key={key} value={value}>
              {value}
            </div>
            <div className='flex intake_5_icons_container'>
              {(value === 'Schedule Request'
                || value === 'Address'
                || value === 'Pool'
                || value === 'Plaster'
                || value === 'Supervisor'
                || value === 'Ready for Work') &&
                <div className='pr-[10px] intake_5_pencil_icon cursor-pointer'>
                  <img src='/pencil_icon.svg' alt='pencil icon' onClick={() => editButtonClick(key)} />
                </div>
              }

              <div className='intake_5_show_more_icon'
                onClick={() => { !arrowsClicked.includes(value) && setArrowsClicked([...arrowsClicked, value]) }}>
                {arrowsClicked.includes(value) ?
                  <img
                    src='/show_less_arrow.svg'
                    onClick={() => handleShowLessClick(value)}
                    alt='show less arrow' />
                  :
                  <img src='/show_more_arrow.svg' alt='show more arrow' />
                }
              </div>
            </div>
          </div>

          <div className={`bg-subtle-grey text-jet-black p-3 intake_5_expanded_menu rounded
          ${!arrowsClicked.includes(value) ? 'mb-[25px]' : 'hidden'}`}>
            {value === 'Schedule Request' &&
              <>
                <div className={`${!isMobileView && 'flex justify-between pb-2 border-b-2'}`}>
                  <div>
                    Service
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {/* pathname.includes('gunite') */}
                    {capitalize(cookies.get('currentService'))}
                  </div>
                </div>
                { // render ASR cert field on gunite flow only
                  isGunite ? (
                    <div className={`
                    ${!isMobileView && 'flex justify-between py-2 border-b-2'}`}>
                      <div>
                        ASR Certification
                      </div>
                      <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                        {jobDetails[i].guniteType === 'ASR Certification' ? "Yes" : "No"}
                      </div>
                    </div>
                  ) : ''
                }
                <div className={`${!isMobileView && 'flex justify-between pt-2'}`}>
                  <div>
                    Date
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold'}`}>
                    {displayDate}
                  </div>
                </div>
              </>
            }

            {value === 'Builder Information' &&
              <>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between pb-2 border-b-2'
                  }
              `}>
                  <div>
                    First Name
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {builderInfo.firstName}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Last Name
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {builderInfo.lastName}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Business Name
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {builderInfo.bizName}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Business Address
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {builderInfo.bizAddress}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Phone Number
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {builderInfo.phone}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between pt-2'
                  }
              `}>
                  <div>
                    Email Address
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold'}`}>
                    {me.email}
                  </div>
                </div>
              </>
            }

            {value === 'Ready for Work' &&
              <>
                {!isGunite &&
                  <div className={`${!isMobileView && 'flex justify-between items-center'}`}>
                    <div>
                      I confirm that the pool is cleaned/prepped
                    </div>
                    <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold'}`}>
                      {poolDetails[i].weWillPrepPool ? 'Yes' : 'No'}
                    </div>
                  </div>
                }

                {isGunite &&
                  <>
                    <div className={`${!isMobileView && 'flex justify-between items-center'}`}>
                      <div>
                        I confirm that the plumbing is done
                      </div>
                      <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                        {poolDetails[i].plumbingIsDone ? 'Yes' : 'No'}
                      </div>
                    </div>

                    <div className={`${!isMobileView && 'flex justify-between items-center pt-2 border-t-2 mt-2'}`}>
                      <div>
                        I confirm that the pool is dug out
                      </div>
                      <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold'}`}>
                        {poolDetails[i].poolIsDugOut ? 'Yes' : 'No'}
                      </div>
                    </div>
                  </>
                }
              </>
            }

            {value === 'Address' &&
              <>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between pb-2 border-b-2'
                  }
              `}>
                  <div>
                    Job Site Address
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].address}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    City
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].city}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    State
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].state}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Zip Code
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].zipCode}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Gate Code
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].gateCode}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Address Notes
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].notes ? jobDetails[i].notes : 'n/a'}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Residential or Commercial?
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].residentialOrCommercial !== '' ?
                      jobDetails[i].residentialOrCommercial : 'n/a'
                    }
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Hud Job?
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].isHudJob ? 'Yes' : 'No'}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Electricity On Site?
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].electricityOnSite ? 'Yes' : 'No'}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between pt-2'
                  }
              `}>
                  <div>
                    Water On Site?
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold'}`}>
                    {jobDetails[i].waterOnSite ? 'Yes' : 'No'}
                  </div>
                </div>
              </>
            }

            {value === 'Pool' &&
              <>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between pb-2 border-b-2'
                  }
              `}>
                  <div>
                    Pool Plan
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {poolDetails[i].planUri}
                  </div>
                </div>
                { // render pool dimesnions on plaster flow only
                  !isGunite ? (
                    <>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Deep End Depth
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].deepEndFt ? poolDetails[i].deepEndFt + ' ft ' : 'n/a'}
                          {poolDetails[i].deepEndInches && poolDetails[i].deepEndInches + ' inches'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Shallow End Depth
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].shallowEndFt ? poolDetails[i].shallowEndFt + ' ft ' : 'n/a'}
                          {poolDetails[i].shallowEndInches && poolDetails[i].shallowEndInches + ' inches'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Length
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].lengthFt ? poolDetails[i].lengthFt + ' ft ' : 'n/a'}
                          {poolDetails[i].lengthInches && poolDetails[i].lengthInches + ' inches'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Width
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].widthFt ? poolDetails[i].widthFt + ' ft ' : 'n/a'}
                          {poolDetails[i].widthInches && poolDetails[i].widthInches + ' inches'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Perimeter
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].perimeterFt ? poolDetails[i].perimeterFt + ' ft ' : 'n/a'}
                          {poolDetails[i].perimeterInches && poolDetails[i].perimeterInches + ' inches'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Square Ft
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].squareFt ? poolDetails[i].squareFt + ' ft' : 'n/a'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Bench
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].benchFt ? poolDetails[i].benchFt + ' ft ' : 'n/a'}
                          {poolDetails[i].benchInches && poolDetails[i].benchInches + ' inches'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Tanning Ledge
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].tanningLedgeFt ? poolDetails[i].tanningLedgeFt + ' ft ' : 'n/a'}
                          {poolDetails[i].tanningLedgeInches && poolDetails[i].tanningLedgeInches + ' inches'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Basin?
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].basin ? 'Yes' : 'No'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Spa?
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].spa ? 'Yes' : 'No'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Barstools
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].barstools || 'n/a'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Drain Covers
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].drainCovers || 'n/a'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Fittings
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].fittings || 'n/a'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Steps
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].steps || 'n/a'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between pt-2'
                        }
                    `}>
                        <div>
                          Waterfall
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold'}`}>
                          {poolDetails[i].waterfall || 'n/a'}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Perimeter
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].perimeterFt ? poolDetails[i].perimeterFt + ' ft ' : 'n/a'}
                          {poolDetails[i].perimeterInches && poolDetails[i].perimeterInches + ' inches'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Square Ft
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].squareFt ? poolDetails[i].squareFt + ' ft' : 'n/a'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Depth
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].depth ? poolDetails[i].depth + ' ft' : 'n/a'}
                        </div>
                      </div>

                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Spa?
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].spa ? 'Yes' : 'No'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Negative Edge?
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].negativeEdge ? 'Yes' : 'No'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Raised Beam?
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].raisedBeam ? 'Yes' : 'No'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between py-2 border-b-2'
                        }
                    `}>
                        <div>
                          Retaining Walls?
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                          {poolDetails[i].retainingWalls ? 'Yes' : 'No'}
                        </div>
                      </div>
                      <div className={`
                      ${!isMobileView &&
                        'flex justify-between'
                        } ${asrBranches.includes(jobDetails[i].locationId) ? 'border-b-2 py-2' : 'pt-2'}
                    `}>
                        <div>
                          Notch?
                        </div>
                        <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold'}`}>
                          {poolDetails[i].notch ? 'Yes' : 'No'}
                        </div>
                      </div>
                      { // render waterproofing field on gunite flow only
                        asrBranches.includes(jobDetails[i].locationId) ? (
                          <div className={`${!isMobileView && 'flex justify-between pt-2'}`}>
                            <div>
                              Waterproofing?
                            </div>
                            <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                              {jobDetails[i].waterproofing === true ? "Yes" : "No"}
                            </div>
                          </div>
                        ) : ''
                      }
                    </>
                  )
                }

              </>
            }

            {value === 'Plaster' &&
              <>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between pb-2 border-b-2'
                  }
              `}>
                  <div>
                    Material
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {poolDetails[i].material ? poolDetails[i].material : 'n/a'}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Color
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {poolDetails[i].color ? poolDetails[i].color : 'n/a'}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between pt-2'
                  }
              `}>
                  <div>
                    Estimate
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold'}`}>
                    {poolDetails[i].estimateNeeded ? 'Yes' : 'No'}
                  </div>
                </div>
              </>
            }

            {value === 'Supervisor' &&
              <>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between pb-2 border-b-2'
                  }
              `}>
                  <div>
                    First Name
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].supervisorFName}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Last Name
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].supervisorLName}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between py-2 border-b-2'
                  }
              `}>
                  <div>
                    Phone Number
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold mb-[12px]'}`}>
                    {jobDetails[i].supervisorPhone}
                  </div>
                </div>
                <div className={`
                ${!isMobileView &&
                  'flex justify-between pt-2'
                  }
              `}>
                  <div>
                    Notes
                  </div>
                  <div className={`${!isMobileView ? 'font-bold opacity-60' : 'font-bold'}`}>
                    {jobDetails[i].notes}
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      ))}
    </>
  )
}

export default Summary
