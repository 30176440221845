import React from 'react'
import CalendarJobCardFlash from '../CalendarJobcardFlash'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'

const TimeslotFlashing = ({ scheduledDate, crewId, ftimeslotJobs, fshift, color }) => {
  const { interval, displayMode } = useSchedulerCalendar()

  const cardData = {
    scheduledDate: scheduledDate,
    crewId: crewId,
    fshift: fshift,
  }
  const fTimeslotDisplay = () => {
    if (interval === 'week' && displayMode === 'vertical') {
      return (
        <div
          className={`${ftimeslotJobs.length < 1 ? 'FDroppable' : ''} ${color} bg-opacity-30 basis-5`}
          data-timeslotdata={JSON.stringify(cardData)}
        >
          {ftimeslotJobs.map((flashJob, timeslotIdx) => (
            <CalendarJobCardFlash job={flashJob} key={`fj-${timeslotIdx}`} color={color}/>
          ))}
        </div>
      )
    } else if (interval === 'week' && displayMode === 'horizontal') {
      return (
        <div
          className={`${ftimeslotJobs.length < 1 ? 'FDroppable' : ''} h-min-content min-h-[22px] ${color} bg-opacity-30 basis-5`}
          data-timeslotdata={JSON.stringify(cardData)}
        >
          {ftimeslotJobs.map((flashJob, timeslotIdx) => (
            <CalendarJobCardFlash job={flashJob} key={`fj-${timeslotIdx}`} color={color}/>
          ))}
        </div>
      )
    } else if (interval === 'month' && displayMode === 'vertical') {
      return (
        <div
          className={`${ftimeslotJobs.length < 1 ? 'FDroppable' : ''} ${color} bg-opacity-30 basis-5`}
          data-timeslotdata={JSON.stringify(cardData)}
        >
          {ftimeslotJobs.map((flashJob, timeslotIdx) => (
            <CalendarJobCardFlash job={flashJob} key={`fj-${timeslotIdx}`} color={color}/>
          ))}
        </div>
      )
    } else if (interval === 'month' && displayMode === 'horizontal') {
      return (
        <div
          className={`${ftimeslotJobs.length < 1 ? 'FDroppable' : ''} h-min-content min-h-[15px] ${color} bg-opacity-30 basis-5`}
          data-timeslotdata={JSON.stringify(cardData)}
        >
          {ftimeslotJobs.map((flashJob, timeslotIdx) => (
            <CalendarJobCardFlash job={flashJob} key={`fj-${timeslotIdx}`} color={color}/>
          ))}
        </div>
      )
    }
  } 

  return (
    <>{fTimeslotDisplay()}</>
  )
}

export default TimeslotFlashing