import React from "react";
import _ from 'underscore';
import { useResponsive } from "../../contexts/responsive";

const SchedulerLocationDropdown = ({value, options, placeholder, onChange, role}) => {
  const uniqueId = `location-selector-${_.uniqueId()}`
  const { isMobileView } = useResponsive();

  const mobileDisplayText = (role) => {
    if (role === 'scheduler') return 'Scheduling For:'
    if (role === 'foreman') return 'Location:'
  }

  return (
    <div className={`group relative`}>
      {isMobileView &&
        <div className='text-white font-bold text-xl'>
          {mobileDisplayText(role)}
        </div>
      }
      <select
        id={uniqueId}
        value={value || 1}
        onChange={onChange}
        className={`border rounded focus:outline-none text-white font-bold text-sm overflow-hidden whitespace-nowrap overflow-ellipsis ${isMobileView ? 'bg-[#6788B6]  max-w-[250px]' : 'pb-[4px] pt-[5px] bg-dark-blue h-full max-w-[210px]'}`}
      >
        <option className={`text-white font-bold ${isMobileView ? 'opacity-70' : ''}`} value=''>{placeholder}</option>
          {options?.map((option, i) => (
            <option key={i} className={`text-white font-bold ${isMobileView ? 'opacity-70' : ''}`} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  )
}

export default SchedulerLocationDropdown;
