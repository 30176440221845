import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    width: '100%',
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Helvetica',
  }
});

const NoJobsPDFTemplate = ({ day }) => {
  const currentDate = new Date()

  return (
    <Document title='invoice'>
      <Page size='A4' style={styles.page}>

        <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingBottom: 10 }}>

          <View style={{ width: '30%' }}></View>

          <View style={{ display: 'flex', justifyContent: 'center', width: '40%' }}>
            <Text style={{ fontSize: 14, paddingBottom: 2 }}>Daily Report for {day.getMonth() + 1}-{day.getDate()}-{day.getFullYear()}</Text>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '30%' }}>
            <Text style={{ fontSize: 10, paddingBottom: 2 }}>Generated: {currentDate.getMonth() + 1}-{currentDate.getDate()}-{currentDate.getFullYear()}</Text>
          </View>

        </View>

        <View>
          <Text style={{ fontSize: 16, width: '100%', textAlign: 'center', paddingBottom: 4 }}>No Jobs on {day.toUTCString().substring(0,16)}</Text>
        </View>
      </Page>
    </Document>
  )
}

export default NoJobsPDFTemplate
