import React, { useState, useEffect } from "react";
import { useIntakeForm } from "../../../contexts/intakeForm";
import ProgressPaneMobile from '../../../components/ProgressPaneMobile';
import ProgressPaneDesktop from '../../../components/ProgressPaneDesktop';
import { useResponsive } from '../../../contexts/responsive';
import { useProgress } from "../../../contexts/progress";
import serviceMethods from "../../../service"
import SupervisorForm from "../../../components/SupervisorForm/SupervisorForm";
import "./index.scss";
import Trashcan from "../../../components/Trashcan";

const PlasterIntake3d = () => {
  const { jobDetails, setJobDetails } = useIntakeForm()
  const { navigateNext, navigatePrevious } = useProgress();
  const { isMobileView } = useResponsive();
  const [isChecked, setIsChecked] = useState(jobDetails[0]?.useSameSupervisor);
  const EMAIL_REGEX = /\S+@\S+\.\S+/;

  useEffect(()=>{
    setIsChecked(jobDetails[0]?.useSameSupervisor)
  },[jobDetails[0]?.useSameSupervisor])

  const isError = () => {
    let error = false;
    if (Array.isArray(jobDetails)){
      if (!isChecked){
        jobDetails.forEach((job) => {
          if (!job.supervisorFName
            || !job.supervisorLName
            || !job.supervisorPhone
            || !EMAIL_REGEX.test(job.supervisorEmail)
          ) {
            error = true;
          }
        })
        return error;
      } else {
        if (!jobDetails[0].supervisorFName
          || !jobDetails[0].supervisorLName
          || !jobDetails[0].supervisorPhone
          || !EMAIL_REGEX.test(jobDetails[0].supervisorEmail)
        ) {
          error = true;
        }
        return error;
      }
    }
  }

  const updateSupervisorInfoPlaster = async (e) => {
    e.preventDefault()
    let res;
    if (isChecked){
      const jobsWithSameSupervisor = jobDetails.map((job, i)=>({
        ...job,
        useSameSupervisor: true,
        supervisorEmail: jobDetails[0].supervisorEmail,
        supervisorFName: jobDetails[0].supervisorFName,
        supervisorLName: jobDetails[0].supervisorLName,
        supervisorPhone: jobDetails[0].supervisorPhone,
        notificationMethod: jobDetails[0].notificationMethod
      }));
      setJobDetails(jobsWithSameSupervisor)
      res = await serviceMethods.updateSupervisor(jobsWithSameSupervisor)
    } else {
      res = await serviceMethods.updateSupervisor(jobDetails)
    }
    
    if (res.ok) {
      navigateNext()
    }
  }

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className="lg:w-1/3">
        {isMobileView ?
          <ProgressPaneMobile />
          :
          <ProgressPaneDesktop />
        }
      </div>
      <div className={`p-6 lg:p-20 flex flex-col gap-6 lg:gap-12 lg:w-full main-container-custom lg:mb-0
        ${isMobileView &&
        'plaster_intake_3e_content_container_mobile'
        }
      `}>
        {/* Note: this div hidden in mobile */}
        <div className="hidden lg:flex flex-col gap-6">
          <button onClick={() => { navigatePrevious() }}>
            <div className="flex gap-2 items-center">
              <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
              <p className="font-semibold text-gunite-red">Back</p>
            </div>
          </button>
          <div className="flex flex-col gap-1">
            <p className="text-secondary-blue font-bold text-4xl">Job site information</p>
            <p className="text-secondary-blue/60 font-semibold text-base">Information about job site, address, pool plan, and job site supervisor.</p>
          </div>
          <div className="flex gap-[2px] items-center">
            {/* progress bar */}
            <div className="h-2 w-1/4 bg-primary-light" />
            <div className="h-2 w-1/4 bg-primary-light" />
            <div className="h-2 w-1/4 bg-primary-light" />
            <div className="h-2 w-1/4 bg-gunite-red" />
          </div>
        </div>

        {jobDetails.length > 1 &&
          <div className='flex'>
            <input
              type='checkbox'
              id={`useSameSupervisorPlaster`}
              onChange={(e)=>{
                setIsChecked(e.target.checked);
                if (e.target.checked){
                  const updatedJobDetails = jobDetails.map((jobDetail, index) => ({
                    ...jobDetail,
                    useSameSupervisor: true,
                  }));
                  setJobDetails(updatedJobDetails)
                } else {
                  const updatedJobDetails = jobDetails.map((jobDetail, index) => ({
                    ...jobDetail,
                    useSameSupervisor: false,
                  }))
                  setJobDetails(updatedJobDetails)
                }
              }}
              className='text-tertiary-blue mt-[6px] mr-[6px]'
              checked={isChecked}>
            </input>
            
            <div className='text-secondary-blue/60 text-base font-semibold mt-[2px]'>
              Use the same supervisor info for all jobs?
            </div>
          </div>
        }

        {jobDetails.length > 1 && !isChecked ? (
          <>
            {jobDetails.map((job, i) => (
              <>
                <div key={i} className="flex flex-col gap-1">
                  <Trashcan
                    i={i}
                  />
                  <SupervisorForm
                    key={`supForm${i}`}
                    job={job}
                    i={i}
                    jobDetails={jobDetails}
                    setJobDetails={setJobDetails}
                  />
                </div>
              </>
            ))}
          </>
          ) : (
            <SupervisorForm
              job={jobDetails[0]}
              i={0}
              jobDetails={jobDetails}
              setJobDetails={setJobDetails}
            />
          )
        }
        <button
          className={`w-full rounded p-3 text-2xl font-bold ${isError() ? 'bg-black/10 text-black/40 cursor-not-allowed' : 'bg-gunite-red text-white cursor-pointer'}`}
          disabled={isError()}
          onClick={updateSupervisorInfoPlaster}
        >
          Continue
        </button>
      </div>
    </div>
  )
}

export default PlasterIntake3d;
