import React from 'react'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'
import Timeslot from '../Timeslot'
import TimeslotFlashing from '../TimeslotFlashing'

const Crew = (props) => {
  const { crew, day, crewJobs } = props
  const { displayMode } = useSchedulerCalendar()

  const timeslotJobsFilter = (shift) => {
    const data = crewJobs?.filter((calendarJob) => (
      shift === calendarJob?.shift
    ))
    return data[0] //return a single job or undefined (NOT an array)
  }

  const FTimeslotJobsFilter = (fshift) => {
    const data = crewJobs?.filter((flashJob) => (fshift === flashJob?.fshift))
    return data //return an array containing 0, 1, or multiple jobs (other things should keep it from being more than 2 jobs but this filter could theoretically return an unlimited number)
  }

  return (
    <>
      {(displayMode === 'vertical') ? (
        <div className={`h-full w-full flex flex-col`}>
          <Timeslot scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('1')} shoot={'1'} color={crew.color} />
          <TimeslotFlashing scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('1')} fshift={'1'} color={crew.color}/>
          <Timeslot scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('2')} shoot={'2'} color={crew.color} />
          <TimeslotFlashing scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('2')} fshift={'2'} color={crew.color}/>
          <Timeslot scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('3')} shoot={'3'} color={crew.color} />
          <TimeslotFlashing scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('3')} fshift={'3'} color={crew.color}/>
        </div>
      ) : (
        <div className={`h-auto`} >
          <Timeslot scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('1')} shoot={'1'} color={crew.color}/>
          <TimeslotFlashing scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('1')} fshift={'1'} color={crew.color}/>
          <Timeslot scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('2')} shoot={'2'} color={crew.color}/>
          <TimeslotFlashing scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('2')} fshift={'2'} color={crew.color}/>
          <Timeslot scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('3')} shoot={'3'} color={crew.color}/>
          <TimeslotFlashing scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('3')} fshift={'3'} color={crew.color}/>
        </div>
      )}
    </>
  )
}

export default Crew
