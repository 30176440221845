//example 8.26.2024
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return `${date.getUTCMonth() + 1}.${date.getUTCDate()}.${date.getUTCFullYear()}`
};

//example 9:00 AM, 1:00 PM
const formatTime = (date, leadingZero = true) => {
  const formatted = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: 'UTC',
  }).format(new Date(date));

  const [hour, rest] = formatted.split(':');
  if (leadingZero) {
    return `${hour.padStart(2, '0')}:${rest}`;
  } else {
    return `${parseInt(hour)}:${rest}`;
  }
}

const formatTime24hr = (date, leadingZero = true) => {
  const formatted = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    timeZone: 'UTC',
  }).format(new Date(date));

  const [hour, rest] = formatted.split(':');
  if (leadingZero) {
    return `${hour.padStart(2, '0')}:${rest}`;
  } else {
    return `${parseInt(hour)}:${rest}`;
  }
}


//ie. 'November 23, 2021'
const formatDateLong = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  }).format(new Date(date));
};

const allStatusDisplayOptions = {
  deletedAt: 'Deleted',
  archivedAt: 'Archived',
  completedAt: 'Completed',
  scheduledAt: 'Scheduled',
  readyAt: 'Ready for calendar',
  backloggedAt: 'Backlog',
  submittedAt: 'Intake',
  none: 'Draft'
};

const getJobStatus = (job, displayOptions = allStatusDisplayOptions) => {
  var statusField = 'none'
  if (job.deletedAt) {
    statusField = 'deletedAt';
  } else if (job.archivedAt) {
    statusField = 'archivedAt';
  } else if (job.completedAt) {
    statusField = 'completedAt';
  } else if (job.scheduledAt) {
    statusField = 'scheduledAt';
  } else if (job.readyAt) {
    statusField = 'readyAt';
  } else if (job.backloggedAt) {
    statusField = 'backloggedAt';
  } else if (job.submittedAt) {
    statusField = 'submittedAt';
  }

  return displayOptions[statusField];
}

const formatPhoneNumber = (phoneNumber) => {
  const areaCode = phoneNumber?.slice(0, 3);
  const firstThree = phoneNumber?.slice(3, 6);
  const lastFour = phoneNumber?.slice(6, 10);
  return `(${areaCode}) ${firstThree} ${lastFour}`;
};

const stateAbbreviator = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: "IL",
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VI',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY'
}

//Location ID's for Branches we have ASR and waterproofing turned on for
const asrBranches = [3, 4, 8]

export { getJobStatus, allStatusDisplayOptions, formatDate, formatTime, formatTime24hr, formatDateLong, formatPhoneNumber, stateAbbreviator, asrBranches };
