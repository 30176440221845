import PaginationFooterBar from '../PaginationFooterBar'
import PopupSearchBox from '../PopupSearchBox'
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { formatDate } from '../../utils'
import { FaRegTrashAlt } from "react-icons/fa";

const FilesBox = ({
  allowItemSelection,
  selectedIds=[],
  setSelectedIds,
  searchTerm,
  setSearchTerm,
  currentPage,
  setCurrentPage,
  allItems,
  RESULTS_PER_PAGE,
  sortBy,
  sortAscending,
  handleColumnHeaderClick,
  displayItems,
  handleTrashClick,
}) => {
  const handleCheckboxChange = (fileUri) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(fileUri)) {
        return prevSelectedIds.filter((uri) => uri !== fileUri);
      } else {
        return [...prevSelectedIds, fileUri];
      }
    });
  };

  const handleMainCheckboxChange = () => {
    if (selectedIds?.length === displayItems?.length) {
      setSelectedIds([]);
    } else {
      const allFileUris = displayItems.map(file => file.fileUri);
      setSelectedIds(allFileUris);
    }
  };
    
  const allChecked = selectedIds?.length === displayItems?.length && selectedIds?.length > 0;

  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <>
      {(displayItems.length === 0) ? (
        <div className="flex justify-center items-center h-32">
          <p className="text-gray-blue">No files found</p>
        </div>
      ) : (
        <div className={`bg-white rounded-2xl w-full sm:w-5/6 my-6 flex flex-col border border-navy-light `}>
          <div className="h-16 relative flex justify-between items-center px-6  rounded-t-xl border-b border-navy-light bg-opacity-20 bg-navy-bright">
            <h1 className="text-xl font-semibold text-dark-blue">Files</h1>
            <div className='flex flex-row'>
              {allowItemSelection && selectedIds.length > 0 && (
                <FaRegTrashAlt 
                  onClick={handleTrashClick}
                  size={20} 
                  className="mr-2 cursor-pointer"
                />
              )}
              <PopupSearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </div>
          </div> 
          <div className="flex py-2 px-2 border-navy-light bg-opacity-20 bg-navy-bright justify-between">
            <div className='flex flex-row w-1/2 mr-4 items-center cursor-pointer select-none'>
              {allowItemSelection &&
                <input
                  type="checkbox"
                  className="mr-2 rounded checked:bg-dark-blue"
                  checked={allChecked}
                  onChange={handleMainCheckboxChange}
                />
              }
                <div
                  className='flex flex-row'
                  onClick={() => handleColumnHeaderClick('fileUri')}
                >
                  <p className='text-gray-blue text-sm whitespace-nowrap'>Name</p>
                  {sortBy === 'fileUri' && (
                    sortAscending ? <FaArrowUp className="mt-1 text-gray-blue text-xs pl-1" /> : <FaArrowDown className="mt-1 text-gray-blue text-xs pl-1" />
                  )}
                </div>
            </div>
            <div className='flex flex-row w-1/2 items-center cursor-pointer select-none' 
                onClick={() => handleColumnHeaderClick('createdAt')}>
                <p className='text-gray-blue text-sm whitespace-nowrap'>Upload date</p>
                {sortBy === 'createdAt' && (
                  sortAscending ? <FaArrowUp className="mt-1 text-gray-blue text-xs pl-1" /> : <FaArrowDown className="mt-1 text-gray-blue text-xs pl-1" />
                )}
            </div>
          </div>
          {/* files go here */}
          {displayItems.map((file, index) => (
          <div className="flex flex-row py-2 px-2 border-b border-navy-light" key={`location-files-${index}`}>
            <div className='w-1/2 mr-4 flex-shrink-0 flex min-w-0 items-center'>
              {allowItemSelection &&
                <input
                  type="checkbox"
                  className="mr-2 rounded checked:bg-dark-blue"
                  checked={selectedIds.includes(file.fileUri)}
                  onChange={() => handleCheckboxChange(file.fileUri)}
                />
              }
              <a href={`${apiUrl}/${file.fileUri}`} download={`${apiUrl}/${file.fileUri}`} className="flex-1 min-w-0">
                <p className="cursor-pointer text-dark-blue font-medium text-md truncate whitespace-nowrap overflow-hidden" onClick={() => { }}>
                  {file.fileUri}
                </p>
              </a>
            </div>
            <div className='text-gray-blue w-1/2 flex-shrink'>{formatDate(file.createdAt)}</div>
          </div>
        ))}
          <PaginationFooterBar 
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            allItems={allItems}
            resultsPerPage={RESULTS_PER_PAGE}
          />
        </div>
      )}
    </>
  );
}

export default FilesBox;
